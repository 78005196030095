/* eslint-disable react/no-unknown-property */
/* eslint-disable react/prop-types */
/** @jsxImportSource theme-ui */
import React from 'react';
import { Link } from 'react-router-dom';
import { jsx } from 'theme-ui';

const ListHeading = ({ heading }) => (
  <h4 sx={{ marginBottom: '20px', fontWeight: 600, fontSize: 20, lineHeight: 1.2, color: '#FFF' }}>
    {heading}
  </h4>
);

const ListItem = ({ href, name, to }) => {
  return (
    <li sx={{ '& + &': { marginTop: '8px' } }}>
      {to ? (
        <Link
          sx={{
            fontSize: '15px',
            lineHeight: 1.5,
            fontWeight: 400,
            color: 'rgb(156, 156, 156)',
          }}
          to={to}
        >
          {name}
        </Link>
      ) : (
        <a
          sx={{
            fontSize: '15px',
            lineHeight: 1.5,
            fontWeight: 400,
            color: 'rgb(156, 156, 156)',
          }}
          href={href}
          target="_blank"
          rel="noreferrer noopener"
        >
          {name}
        </a>
      )}
    </li>
  );
};

const List = ({ items }) => (
  <ul>
    {items.map(({ href, name, to }) => (
      <ListItem key={name} href={href} name={name} to={to} />
    ))}
  </ul>
);

const LinksList = ({ heading, items }) => {
  return (
    <div>
      <ListHeading heading={heading} />
      <List items={items} />
    </div>
  );
};

export default LinksList;
