import React from 'react';
import { Route, Switch } from 'react-router-dom';
import CasinoBanner from '@groot/components/CasinoBanner';
import NewHeader from './components/NewHeader/Header';
import NewFooter from './components/NewFooter/NewFooter';
import ScrollToTop from './components/ScrollToTop';
import Audits from './routes/Audits';
import Contract from './routes/Contract';
import Gro from './routes/Gro';
import GroETH from './routes/GroETH';
import Docs from './routes/Docs';
import Home from './routes/Home';
import Ecosystem from './routes/Ecosystem';
import Bridge from './routes/Bridge';
import Staking from './routes/Staking';
import Yield from './routes/Yield';
import Groot from './routes/Groot';
import WheatGroSwap from './routes/WheatGroSwap';
import WalletModal from './components/WalletModal';
import Gallery from './routes/Gallery';
import XYieldCounter from './components/XYieldCountdown';

import 'aos/dist/aos.css';

function App() {
  return (
    <ScrollToTop>
      <NewHeader />
      {/* <CasinoBanner /> */}
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/audits" component={Audits} />
        <Route exact path="/contract" component={Contract} />
        <Route exact path="/gro" component={Gro} />
        <Route exact path="/gro-eth" component={GroETH} />
        <Route exact path="/docs" component={Docs} />
        <Route exact path="/ecosystem" component={Ecosystem} />
        <Route exact path="/staking" component={Staking} />
        <Route exact path="/gallery" component={Gallery} />
        <Route
          exact
          path="/cultivator"
          component={() => {
            window.location.href = 'http://emp.money/#/daas/cultivator';
            return null;
          }}
        />
      </Switch>

      <NewFooter />
      <WalletModal />
    </ScrollToTop>
  );
}

export default App;
