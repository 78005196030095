/** @jsxImportSource theme-ui */
import React from 'react';

// eslint-disable-next-line react/prop-types
const ExternalLink = ({ children, href, ...props }) => {
  return (
    <a href={href} target="_blank" rel="noreferrer noopener" {...props}>
      {children}
    </a>
  );
};

export default ExternalLink;
