import { useState, useEffect, useRef } from 'react';
import { useWeb3React } from '@web3-react/core';
import LP from '@groot/consts/abi/lpToken.json';
import BEP20 from '@groot/consts/abi/erc20.json';
import useRefresh from './useRefresh';
import { getWeb3NoAccount } from '@groot/shared/util';
import Pairs from '@groot/consts/pairs';
import Tokens from '@groot/consts/tokens';
import { getAddress } from '@groot/utils/addressHelper';
import { ERC20 } from '@groot/shared/data-access/contracts';

export const useGetGroPrice = () => {
  const [groPrice, setGroPrice] = useState(0);
  const didMount = useRef(false);
  const { chainId } = useWeb3React();
  const { fastRefresh } = useRefresh();

  useEffect(() => {
    didMount.current = true;

    const getNativePrice = async () => {
      const web3 = getWeb3NoAccount(1);
      const ethInstance = new web3.eth.Contract(LP, getAddress(Pairs.NATIVE_USD, 1));
      const ethReserves = await ethInstance.methods.getReserves().call();
      const token0 = await ethInstance.methods.token0().call();
      const token1 = await ethInstance.methods.token1().call();
      const token0Instance = new web3.eth.Contract(ERC20, token0);
      const token1Instance = new web3.eth.Contract(ERC20, token1);
      const token0Name = await token0Instance.methods.name().call();
      const decimal0 = await token0Instance.methods.decimals().call();
      const decimal1 = await token1Instance.methods.decimals().call();

      const reserve0 = ethReserves[0] / 10 ** decimal0;
      const reserve1 = ethReserves[1] / 10 ** decimal1;
      if (token0Name.toLowerCase().includes('usd')) {
        return reserve0 / reserve1;
      } else {
        return reserve1 / reserve0;
      }
    };

    const getPrice = async () => {
      try {
        const web3 = getWeb3NoAccount(1);

        const groInstance = new web3.eth.Contract(LP, getAddress(Pairs.GRO_NATIVE, 1));

        const groReserves = await groInstance.methods.getReserves().call();

        const token0 = await groInstance.methods.token0().call();
        const tokenInstance = new web3.eth.Contract(ERC20, token0);
        const tokenName = await tokenInstance.methods.name().call();

        const ethPrice = await getNativePrice();

        let gPrice;
        if (tokenName.toLowerCase().includes('gro')) {
          gPrice = (groReserves[1] / groReserves[0]) * ethPrice;
        } else {
          gPrice = (groReserves[0] / groReserves[1]) * ethPrice;
        }
        if (didMount.current) {
          setGroPrice(gPrice);
        }
      } catch (err) {
        return;
      }
    };

    getPrice();

    return () => {
      didMount.current = false;
    };
  }, [didMount, chainId, fastRefresh]);

  return groPrice;
};

export const useGetGroData = () => {
  const didMount = useRef(false);
  const { chainId } = useWeb3React();
  const { fastRefresh } = useRefresh();
  const [groBurnt, setBurnt] = useState(0);
  const [groTotalSupply, setTotalSupply] = useState(0);
  const [groCirculation, setCirculation] = useState(0);

  useEffect(() => {
    didMount.current = true;

    const getData = async () => {
      const web3 = getWeb3NoAccount(chainId ? chainId : 1);
      const ethInstance = new web3.eth.Contract(
        BEP20,
        getAddress(Tokens.GRO, chainId ? chainId : 1)
      );

      const _totalSupply = await ethInstance.methods.totalSupply().call();
      const _burnt = await ethInstance.methods
        .balanceOf('0x000000000000000000000000000000000000dead')
        .call();
      const _bankroll = await ethInstance.methods
        .balanceOf('0x34a583c62cbbe134c621cd01cd46f7f4f11fd52d')
        .call();

      if (didMount.current) {
        const __totalSupplyNum = _totalSupply / 1e18;
        setTotalSupply(__totalSupplyNum - _burnt / 1e18);
        setCirculation(__totalSupplyNum - _burnt / 1e18 - _bankroll / 1e18);
        setBurnt(_burnt / 1e18);
      }
    };
    getData();

    return () => {
      didMount.current = false;
    };
  }, [didMount, chainId, fastRefresh]);

  return { groBurnt, groTotalSupply, groCirculation };
};
