/** @jsxImportSource theme-ui */
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Button, Flex, Select } from 'theme-ui';
import ConnectBtn from './ConnectBtn';
import SideMenu from './SideMenu';
import { theme } from '@groot/shared/util';
import Doc from 'assets/doc/Litepaper.pdf';
import DocBonzai from 'assets/doc/BonzaiLitepaper.pdf';

import { ReactComponent as TelegramIcon } from '../NewFooter/icons/Telegram.svg';
import { ReactComponent as TwitterIcon } from '../NewFooter/icons/Twitter.svg';
import { ReactComponent as MediumIcon } from '../NewFooter/icons/Medium.svg';

const Menu = styled.ul`
  display: none;

  ${theme.mediaQuery.min.md} {
    display: flex;
    align-items: center;
  }

  > li {
    position: relative;

    > a {
      padding: 4px;
      font-size: 16px;
      line-height: 1.25;
    }
  }

  a {
    display: block;
    line-height: 1;
    cursor: pointer;
    color: #ffffff;
  }
`;

const MenuSeparator = styled.li`
  width: 1px;
  height: 15px;
  margin: -2px 24px 0;
  background-color: #fff;
`;

// eslint-disable-next-line react/prop-types
export default function NavMenu() {
  const { pathname } = useLocation();
  const isBridgePath = pathname === '/bridge';

  return (
    <Flex sx={{ alignItems: 'center' }}>
      <Menu>
        <li>
          <a href="https://app.growthdefi.com/">App</a>
        </li>
        <MenuSeparator />

        <li>
          <a
            sx={{ display: 'flex', aligItems: 'center' }}
            href="https://t.me/GrowthDefi_on_ETH"
            target="_blank"
            rel="noreferrer noopener"
          >
            <TelegramIcon />
          </a>
        </li>
        <li>
          <a
            sx={{ display: 'flex', aligItems: 'center', marginLeft: 20 }}
            href="https://twitter.com/PerpetualDice3"
            target="_blank"
            rel="noreferrer noopener"
          >
            <TwitterIcon />
          </a>
        </li>
        <li>
          <a
            sx={{ display: 'flex', aligItems: 'center', marginLeft: 20 }}
            href="https://growthdefi.medium.com"
            target="_blank"
            rel="noreferrer noopener"
          >
            <MediumIcon />
          </a>
        </li>
        <li
          sx={{
            '@media (max-width: 1199px)': {
              display: 'none',
            },
          }}
        >
          <a
            sx={{ padding: '0 !important' }}
            href={DocBonzai}
            target="_blank"
            rel="noreferrer noopener"
          >
            <Button sx={{ height: 50, px: 30, marginLeft: 30, textTransform: 'uppercase' }}>
              Bonzai Litepaper
            </Button>
          </a>
        </li>
        <li
          sx={{
            '@media (max-width: 1199px)': {
              display: 'none',
            },
          }}
        >
          {isBridgePath ? (
            <ConnectBtn sx={{ height: 50, marginLeft: 30 }} />
          ) : (
            <a
              sx={{ padding: '0 !important' }}
              href={Doc}
              target="_blank"
              rel="noreferrer noopener"
            >
              <Button sx={{ height: 50, px: 30, marginLeft: 30, textTransform: 'uppercase' }}>
                Litepaper
              </Button>
            </a>
          )}
        </li>
      </Menu>
      <SideMenu isBridgePath={isBridgePath} />
    </Flex>
  );
}
