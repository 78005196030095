/* eslint-disable */
import React, { createContext } from 'react';

// let ContextProps = {
//   lastUpdatedTime: number,
//   setLastUpdatedTime: React.Dispatch<React.SetStateAction<number>>
// }

const GrootUiYieldContext = createContext({
  lastUpdatedTime: Date.now(),
  setLastUpdatedTime: () => {},
});

export default GrootUiYieldContext;
