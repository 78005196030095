import styled from 'styled-components/macro';
import { Button } from 'theme-ui';
import { theme } from '@groot/shared/util';

import bgPattern from 'assets/img/bgPattern.png';
import { BsArrowLeftRight } from 'react-icons/bs';

export const Page = styled.div`
  margin-bottom: 100px;
  padding-top: 62px;
  padding-bottom: 62px;
  background-image: url(${bgPattern});
  background-position: bottom center;
  background-size: 100% auto;
  background-repeat: no-repeat;
`;

export const Row = styled.div`
  display: flex;
  flex-flow: nowrap;

  ${theme.mediaQuery.max.sm} {
    flex-direction: column;
  }

  & + & {
    margin-top: 50px;

    ${theme.mediaQuery.min.md} {
      margin-top: 62px;
    }
  }
`;

export const Content = styled.div`
  h1 {
    margin-bottom: 5px;
    font-weight: 600;

    ${theme.mediaQuery.min.md} {
      font-size: 50px;
    }
  }

  ${theme.mediaQuery.min.sm} {
    margin-right: 30px;
  }

  ${theme.mediaQuery.min.md} {
    margin-right: 98px;
  }
`;

export const ContentBox = styled.div`
  margin-top: 20px;
  padding: 20px;
  background: #1c1c1c;
  border-radius: 5px;
  text-align: center;
  font-size: 12px;
  line-height: 1.82;
  color: #9c9c9c;
`;

export const ContentButton = styled(Button)`
  width: 100%;
  height: 50px !important;

  ${theme.mediaQuery.min.md} {
    max-width: 36%;
  }

  & + & {
    margin: 12px 0 0;

    ${theme.mediaQuery.min.sm} {
      margin: 0 0 0 20px;
    }
  }
`;

export const OutlineButton = styled(ContentButton)`
  background: #131313 !important;
  border: 1px solid #6b40e8 !important;
  color: #6b40e8 !important;
`;

export const Buttons = styled.div`
  display: flex;
  margin-top: 20px;

  ${theme.mediaQuery.max.sm} {
    flex-direction: column;
  }
`;

export const Aside = styled.aside`
  flex-shrink: 0;
  width: 100%;
  margin-top: 30px;

  ${theme.mediaQuery.min.sm} {
    margin: 0;
    width: 340px;
  }

  ${theme.mediaQuery.min.md} {
    width: 460px;
  }
`;

export const CardButton = styled(Button)`
  width: 100%;
`;

export const Card = styled.div`
  padding: 30px;
  border-radius: 20px;

  ${CardButton} {
    height: 60px;
    margin-top: 24px;
    border-radius: 10px;
  }
`;

export const CardTitle = styled.h3`
  margin-bottom: 20px;
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  font-weight: 500;
  font-size: 18px !important;
  color: #ffffff;
`;

export const CardNote = styled.p`
  display: flex;
  align-items: center;
  margin-top: 24px;
  font-weight: 500;
  font-size: 14px;
`;

export const GroCard = styled(Card)`
  background: linear-gradient(316.77deg, #4457d1 -7.26%, #12c8df 46.75%, #fae071 100%);
`;

export const WheatCard = styled(Card)`
  background: linear-gradient(315deg, #cc3cff 0%, #2e43fe 50.36%, #eb79ff 100%);
`;

export const FormControl = styled.label`
  display: flex;
  align-items: center;
  height: 60px;
  margin-top: 6px;
  padding: 12px;
  background: #fff;
  border-radius: 10px;
`;

export const FormControlName = styled.label`
  margin-bottom: 8px;
  font-weight: 600;
  line-height: 17px;
  color: #fff;
`;

export const FormControlToken = styled.div`
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 18px;
  color: #2d2d2d;

  img {
    margin-right: 8px;
  }
`;

export const FormControlInput = styled.input`
  width: 100%;
  padding: 2px 15px;
  background: none;
  border: 0;
  outline: none;
  text-align: right;
  font-weight: 500;
  font-size: 21px;
  color: #2d2d2d;
  -moz-appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ::placeholder,
  ::-webkit-input-placeholder {
    color: #2d2d2d;
  }
  :-ms-input-placeholder {
    color: #2d2d2d;
  }
`;

export const FormControlButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  padding: 2px 16px;
  background: none;
  border: 1px solid #434343;
  border-radius: 15px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
  cursor: pointer;
  color: #2d2d2d;
  transition: 0.3s;
  margin-left: 10px;
  &:hover {
    background-color: #2d2d2d;
    color: #fff;
  }
`;

export const StyledChainContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin: 16px 0;
`;

export const StyledChainItemContainer = styled.div`
  flex-grow: 1;
`;

export const StyledChainItem = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 48px;
  padding: 4px 10px;
  background-color: #fff;
  border-radius: 10px;
`;

export const StyledChainItemName = styled.p`
  margin-left: 8px;
  font-weight: 500;
  color: #2d2d2d;
`;

export const StyledChainImage = styled.img`
  height: 32px;
`;

export const StyledReverseButton = styled(BsArrowLeftRight)`
  width: 36px;
  height: 36px;
  margin: 6px 12px;
  padding: 8px;
  border: 2px solid #fff;
  border-radius: 50%;
  font-size: larger;
  cursor: pointer;
  color: #fff;
  transition: 0.3s;

  &:hover {
    background-color: #fff;
    color: #6b40e8;
  }
`;
