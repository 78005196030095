import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ReactComponent as MenuIcon } from './images/MenuIcon.svg';

const StyledBurgerButton = styled.button`
  margin: 0 0 0 30px;
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;
`;

const BurgerButton = ({ onClick }) => (
  <StyledBurgerButton onClick={onClick}>
    <MenuIcon />
  </StyledBurgerButton>
);

export default BurgerButton;

BurgerButton.propTypes = {
  onClick: PropTypes.func,
};
