import { useState, useMemo, useContext } from 'react';
import { useWeb3React } from '@web3-react/core';

import { stkGROContractABI, GROContractABI } from '../data-access/contracts';
import { getWeb3, numberToBN } from '../util';

import useNotification from '../../hooks/useNotification';
import GrootUiYieldContext from '../context/groot-ui-yield-context';
import { GRO_CONTRACT_ADDRESS, STK_GRO_CONTRACT_ADDRESS } from '../consts/groot-ui-yield-consts';

const MAX_GRO = '1000000000000000000000000';

export default function useGROStaking() {
  const { addNotification } = useNotification();
  const { setLastUpdatedTime } = useContext(GrootUiYieldContext);

  const [depositAmount, setDepositAmount] = useState('');
  const [withdrawAmount, setWithdrawAmount] = useState('');

  const { account, library, chainId } = useWeb3React();
  const address = account;
  const web3 = getWeb3(library);

  const GROContractInstance = useMemo(
    () => new web3.eth.Contract(GROContractABI, GRO_CONTRACT_ADDRESS),
    [web3]
  );
  const stkGROContractInstance = useMemo(
    () => new web3.eth.Contract(stkGROContractABI, STK_GRO_CONTRACT_ADDRESS),
    [web3]
  );

  const isChainValid = () => {
    if (chainId !== 1) {
      addNotification({
        title: 'Chain Error',
        message: 'Please check if Ethereum main network is chosen.',
        type: 'danger',
      });
      return false;
    }
    return true;
  };

  const onGRODeposit = async () => {
    const depositAmountNumber = Number(depositAmount);
    if (depositAmount <= 0 || !isChainValid()) {
      return;
    }

    const depositAmountInWei = numberToBN(depositAmountNumber, 1e18);
    // check allowance
    const allowance = await GROContractInstance.methods
      .allowance(address, STK_GRO_CONTRACT_ADDRESS)
      .call();
    if (allowance < depositAmountInWei) {
      await GROContractInstance.methods.approve(STK_GRO_CONTRACT_ADDRESS, MAX_GRO).send({
        from: address,
      });
    }

    try {
      await stkGROContractInstance.methods.deposit(depositAmountInWei).send({
        from: address,
      });
      addNotification({
        title: 'Success',
        message: `You have successfully deposited ${depositAmount} GRO`,
        type: 'success',
      });
    } catch (err) {
      console.log(err);
      addNotification({
        title: 'Failed!',
        message: 'Deposit Failed. Please check if you have enough balance.',
        type: 'danger',
      });
    } finally {
      setLastUpdatedTime(Date.now());
    }
  };

  const onGROWithdraw = async () => {
    const withdrawAmountNumber = Number(withdrawAmount);
    if (withdrawAmountNumber <= 0 || !isChainValid()) {
      return;
    }

    const withdrawAmountInWei = numberToBN(withdrawAmountNumber, 1e18);
    try {
      await stkGROContractInstance.methods.withdraw(withdrawAmountInWei).send({
        from: address,
      });
      addNotification({
        title: 'Success',
        message: `You have successfully withdrawn ${withdrawAmount} GRO`,
        type: 'success',
      });
    } catch (err) {
      console.log(err);
      addNotification({
        title: 'Failed!',
        message: 'Withdraw Failed. Please check if you have enough balance.',
        type: 'danger',
      });
    } finally {
      setLastUpdatedTime(Date.now());
    }
  };

  return {
    depositAmount,
    setDepositAmount,
    withdrawAmount,
    setWithdrawAmount,
    onGRODeposit,
    onGROWithdraw,
  };
}
