/** @jsxImportSource theme-ui */
import React from 'react';
import { useWeb3React } from '@web3-react/core';
import { useLocation } from 'react-router-dom';
import { Button } from 'theme-ui';

import useConnectHandler from '../../hooks/useConnectHandler';

const parseAddress = (address) => {
  if (address) {
    const frontTail = address.substring(0, 5);
    const endTail = address.substring(address.length - 3, address.length);
    return `${frontTail}...${endTail}`;
  }
  return 'Connect';
};

export default function ConnectBtn(props) {
  const { account, chainId } = useWeb3React();
  const { pathname } = useLocation();

  const chains = [56, 250, 43114, undefined];
  const chainSupported = chains.includes(chainId);

  const { onConnectClick } = useConnectHandler();

  return (
    <Button {...props} onClick={onConnectClick}>
      {!chainId || chainSupported ? parseAddress(account) : 'Wrong Network'}
    </Button>
  );
}
