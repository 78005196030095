import styled from 'styled-components';
import { theme } from '@groot/shared/util';
import patternImage from './images/PatternImage.png';

export const Page = styled.main`
  padding-bottom: 80px;
  background-image: url(${patternImage});
  background-repeat: no-repeat;
  background-position: center 100px;

  ${theme.mediaQuery.min.sm} {
    background-size: 100% auto;
    padding: 0 0 120px;
  }
`;

export const Hero = styled.section`
  padding: 60px 0 80px;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: bottom center;

  ${theme.mediaQuery.max.sm} {
    text-align: center;
  }

  ${theme.mediaQuery.min.sm} {
    margin-bottom: 62px;
    padding: 62px 0;
  }
`;

export const HeroContent = styled.article`
  width: 100%;

  ${theme.mediaQuery.min.sm} {
    width: 50%;
    max-width: 570px;
    margin-top: 42px;
    padding-right: 15px;
  }

  p {
    color: #9c9c9c;

    ${theme.mediaQuery.max.sm} {
      margin-bottom: 20px;
    }
  }
`;

export const HeroObject = styled.article`
  width: 100%;

  ${theme.mediaQuery.min.sm} {
    width: 50%;
    max-width: 540px;
    padding-left: 15px;
  }

  img {
    display: block;
    width: 100%;
  }
`;

export const CardListHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  img {
    width: 38px;
    margin-right: 10px;
  }

  span {
    font-weight: 600;
    font-size: 20px;
    line-height: 1.2;
  }
`;

export const CardList = styled.div`
  margin-bottom: 40px;
  padding: 15px;
  background-color: #1c1c1c;
  border-radius: 5px;

  ${theme.mediaQuery.min.sm} {
    margin-bottom: 70px;
    padding: 30px;
  }

  &:last-child {
    margin: 0;
  }
`;

export const Card = styled.article`
  display: flex;
  align-items: center;
  padding: 16px;
  background-color: #2d2d2d;
  border-radius: 5px;

  & + & {
    margin-top: 10px;
  }
`;

export const CardLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  margin-right: 16px;
  border: 1px solid #444444;
  border-radius: 50%;

  ${theme.mediaQuery.min.sm} {
    width: 58px;
    height: 58px;
  }

  img {
    height: 18px;

    ${theme.mediaQuery.min.sm} {
      height: 24px;
    }
  }
`;

export const CardTitle = styled.div`
  flex-shrink: 0;
  margin-right: 16px;
  font-weight: 600;
`;

export const CardAddress = styled.div`
  margin-left: auto;
  margin-right: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #16c6e8;
`;

export const CardCta = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  margin-left: 16px;
  border: 1px solid #4b4b4b;
  border-radius: 5px;
  cursor: pointer;

  ${theme.mediaQuery.min.sm} {
    width: 38px;
    height: 38px;
  }

  img {
    width: 16px;
  }
`;
