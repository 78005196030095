/** @jsxImportSource theme-ui */
import React from 'react';
import AOS from 'aos';
import { Flex, Text } from 'theme-ui';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import useNotification from '@groot/hooks/useNotification';
import Container from '@groot/components/Container';
import Counter from '@groot/components/Counter';

import { theme } from '@groot/shared/util';

import heroImage from './images/HeroImage.png';
import morlogo from './images/MorLogo.png';
import groLogo from './images/GroLogo.png';
import ftmLogo from './images/ftm.png';
import copyIcon from './images/CopyIcon.png';
import bscLogo from './images/BscLogo.png';
import avaxLogo from './images/AvaxLogo.png';

import {
  Page,
  Hero,
  HeroContent,
  HeroObject,
  CardList,
  CardListHeader,
  Card,
  CardLogo,
  CardTitle,
  CardAddress,
  CardCta,
} from './contract.styled';

export default function Contract() {
  const { addNotification } = useNotification();

  React.useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);

  return (
    <Page>
      <Hero>
        <Container>
          <Flex
            sx={{ alignItems: 'center', justifyContent: 'space-between', flexFlow: 'row wrap' }}
          >
            <HeroContent
              data-aos="fade-up"
              data-aos-offset="-340"
              data-aos-delay="0"
              data-aos-duration="600"
              data-aos-easing="ease-in-out"
              data-aos-mirror="false"
              data-aos-once="true"
              data-aos-anchor-placement="top-center"
            >
              <h1 sx={{ ...theme.styles.h1, mb: 20 }}>Contract Addresses</h1>
              <p>Import MOR or GRO to your Wallet on your preferred chain.</p>
            </HeroContent>
            <HeroObject
              data-aos="fade-up"
              data-aos-offset="-340"
              data-aos-delay="100"
              data-aos-duration="600"
              data-aos-easing="ease-in-out"
              data-aos-mirror="false"
              data-aos-once="true"
              data-aos-anchor-placement="top-center"
            >
              <img src={heroImage} />
            </HeroObject>
          </Flex>
        </Container>
      </Hero>
      <Container>
        <CardListHeader
          data-aos="fade-up"
          data-aos-offset="-340"
          data-aos-delay="200"
          data-aos-duration="600"
          data-aos-easing="ease-in-out"
          data-aos-mirror="false"
          data-aos-once="true"
          data-aos-anchor-placement="top-center"
        >
          <img src={bscLogo} />
          <span>Binance Smart Chain</span>
        </CardListHeader>
        <CardList
          data-aos="fade-up"
          data-aos-offset="-340"
          data-aos-delay="200"
          data-aos-duration="600"
          data-aos-easing="ease-in-out"
          data-aos-mirror="false"
          data-aos-once="true"
          data-aos-anchor-placement="top-center"
        >
          <Card>
            <CardLogo>
              <img src={morlogo} />
            </CardLogo>
            <CardTitle>MOR (BSC)</CardTitle>
            <CardAddress>0x87bade473ea0513d4aa7085484aeaa6cb6ebe7e3</CardAddress>

            <CopyToClipboard
              text="0x87bade473ea0513d4aa7085484aeaa6cb6ebe7e3"
              onCopy={() => {
                addNotification({
                  title: '',
                  message: 'Copied address to clipboard!',
                  type: 'success',
                });
              }}
            >
              <CardCta>
                <img src={copyIcon} />
              </CardCta>
            </CopyToClipboard>
          </Card>
          <Card>
            <CardLogo>
              <img src={groLogo} />
            </CardLogo>
            <CardTitle>GRO (BSC)</CardTitle>
            <CardAddress>0x336ed56d8615271b38ecee6f4786b55d0ee91b96</CardAddress>
            <CopyToClipboard
              text="0x336ed56d8615271b38ecee6f4786b55d0ee91b96"
              onCopy={() => {
                addNotification({
                  title: '',
                  message: 'Copied address to clipboard!',
                  type: 'success',
                });
              }}
            >
              <CardCta>
                <img src={copyIcon} />
              </CardCta>
            </CopyToClipboard>
          </Card>
        </CardList>
        <CardListHeader
          data-aos="fade-up"
          data-aos-offset="-340"
          data-aos-delay="300"
          data-aos-duration="600"
          data-aos-easing="ease-in-out"
          data-aos-mirror="false"
          data-aos-once="true"
          data-aos-anchor-placement="top-center"
        >
          <img src={avaxLogo} />
          <span>Avalanche</span>
        </CardListHeader>
        <CardList
          data-aos="fade-up"
          data-aos-offset="-340"
          data-aos-delay="300"
          data-aos-duration="600"
          data-aos-easing="ease-in-out"
          data-aos-mirror="false"
          data-aos-once="true"
          data-aos-anchor-placement="top-center"
        >
          <Card>
            <CardLogo>
              <img src={morlogo} />
            </CardLogo>
            <CardTitle>MOR (AVAX)</CardTitle>
            <CardAddress>0x87BAde473ea0513D4aA7085484aEAA6cB6EBE7e3</CardAddress>
            <CopyToClipboard
              text="0x87BAde473ea0513D4aA7085484aEAA6cB6EBE7e3"
              onCopy={() => {
                addNotification({
                  title: '',
                  message: 'Copied address to clipboard!',
                  type: 'success',
                });
              }}
            >
              <CardCta>
                <img src={copyIcon} />
              </CardCta>
            </CopyToClipboard>
          </Card>
          <Card>
            <CardLogo>
              <img src={groLogo} />
            </CardLogo>
            <CardTitle>GRO (AVAX)</CardTitle>
            <CardAddress>0x72699ba15CC734F8db874fa9652c8DE12093F187</CardAddress>
            <CopyToClipboard
              text="0x72699ba15CC734F8db874fa9652c8DE12093F187"
              onCopy={() => {
                addNotification({
                  title: '',
                  message: 'Copied address to clipboard!',
                  type: 'success',
                });
              }}
            >
              <CardCta>
                <img src={copyIcon} />
              </CardCta>
            </CopyToClipboard>
          </Card>
        </CardList>

        <CardListHeader
          data-aos="fade-up"
          data-aos-offset="-340"
          data-aos-delay="300"
          data-aos-duration="600"
          data-aos-easing="ease-in-out"
          data-aos-mirror="false"
          data-aos-once="true"
          data-aos-anchor-placement="top-center"
        >
          <img src={ftmLogo} />
          <span>Fantom</span>
        </CardListHeader>
        <CardList
          data-aos="fade-up"
          data-aos-offset="-340"
          data-aos-delay="300"
          data-aos-duration="600"
          data-aos-easing="ease-in-out"
          data-aos-mirror="false"
          data-aos-once="true"
          data-aos-anchor-placement="top-center"
        >
          <Card>
            <CardLogo>
              <img src={morlogo} />
            </CardLogo>
            <CardTitle>MOR (FTM)</CardTitle>
            <CardAddress>0x22A6aC883B2f5007486C0D0EBC520747c0702Ad5</CardAddress>
            <CopyToClipboard
              text="0x22A6aC883B2f5007486C0D0EBC520747c0702Ad5"
              onCopy={() => {
                addNotification({
                  title: '',
                  message: 'Copied address to clipboard!',
                  type: 'success',
                });
              }}
            >
              <CardCta>
                <img src={copyIcon} />
              </CardCta>
            </CopyToClipboard>
          </Card>
          <Card>
            <CardLogo>
              <img src={groLogo} />
            </CardLogo>
            <CardTitle>GRO (FTM)</CardTitle>
            <CardAddress>0x91F1430833879272643658f8eD07d60257dDf321</CardAddress>
            <CopyToClipboard
              text="0x91F1430833879272643658f8eD07d60257dDf321"
              onCopy={() => {
                addNotification({
                  title: '',
                  message: 'Copied address to clipboard!',
                  type: 'success',
                });
              }}
            >
              <CardCta>
                <img src={copyIcon} />
              </CardCta>
            </CopyToClipboard>
          </Card>
        </CardList>
      </Container>
    </Page>
  );
}
