/** @jsxImportSource theme-ui */
import React from 'react';
import styled from 'styled-components';
import { theme } from '@groot/shared/util';

import logo from 'assets/img/appLogo.svg';

const StyledLogo = styled.div`
  a {
    display: block;
  }
`;

const LogoImage = styled.img`
  display: block;
  height: 32px;

  ${theme.mediaQuery.min.sm} {
    height: 40px;
  }
`;

const Logo = () => (
  <StyledLogo>
    <a href="/">
      <LogoImage src={logo} alt="Growth Defi" />
    </a>
  </StyledLogo>
);

export default Logo;
