import { useState, useMemo, useContext, useEffect, useCallback } from 'react';
import { GrootContractData } from '@groot/shared/data-access/contracts';
import { getWeb3, numberToBN } from '@groot/shared/util';

import { useWeb3React } from '@web3-react/core';
import GrootUiYieldContext from '@groot/shared/context/groot-ui-yield-context';
import useNotification from '@groot/hooks/useNotification';
import useDebounce from '@groot/shared/hooks/useDebounce';

const GROOT_HARVEST_ADDRESS = GrootContractData.global.smartContracts.GROOT_BNB_HARVEST.contract;
const GRO_YIELD_ADDRESS = GrootContractData.global.smartContracts.GRO_BNB_HARVEST.contract;
const GROOT_HARVEST_ABI = GrootContractData.global.smartContracts.GROOT_BNB_HARVEST.abi;
const GRO_YIELD_ABI = GrootContractData.global.smartContracts.GRO_BNB_HARVEST.abi;
const GROOT_ADDRESS = GrootContractData.global.tokens.gROOT.contract;
const GROOT_ABI = GrootContractData.global.tokens.gROOT.abi;
const GRO_ADDRESS = GrootContractData.global.tokens.GRO.contract;
const GRO_ABI = GrootContractData.global.tokens.GRO.abi;
const MAX_GROOT = '20000000000000000000000';

export default function useGRootHarvest() {
  const { addNotification } = useNotification();
  const { setLastUpdatedTime } = useContext(GrootUiYieldContext);

  const [depositAmount, setDepositAmount] = useState('');
  const [withdrawAmount, setWithdrawAmount] = useState('');
  const [depositFee, setDepositFee] = useState('0.000000');
  const [withdrawFee, setWithdrawFee] = useState('0.000000');
  const [depositAmountGRO, setDepositAmountGRO] = useState('');
  const [withdrawAmountGRO, setWithdrawAmountGRO] = useState('');
  const [depositFeeGRO, setDepositFeeGRO] = useState('0.000000');
  const [withdrawFeeGRO, setWithdrawFeeGRO] = useState('0.000000');

  const debouncedDepositAmount = useDebounce(depositAmount);
  const debouncedWithdrawAmount = useDebounce(withdrawAmount);
  const debouncedDepositAmountGRO = useDebounce(depositAmountGRO);
  const debouncedWithdrawAmountGRO = useDebounce(withdrawAmountGRO);

  const { account, library } = useWeb3React();
  const address = account;
  const web3 = getWeb3(library);

  const harvestContractInstance = useMemo(
    () => new web3.eth.Contract(GROOT_HARVEST_ABI, GROOT_HARVEST_ADDRESS),
    [web3]
  );

  const gRootContractInstance = useMemo(
    () => new web3.eth.Contract(GROOT_ABI, GROOT_ADDRESS),
    [web3]
  );

  const yieldContractInstance = useMemo(
    () => new web3.eth.Contract(GRO_YIELD_ABI, GRO_YIELD_ADDRESS),
    [web3]
  );

  const groContractInstance = useMemo(() => new web3.eth.Contract(GRO_ABI, GRO_ADDRESS), [web3]);

  const calcFee = useCallback(
    async (amountInWei) => {
      const fee = await harvestContractInstance.methods.calcFee(amountInWei).call();
      return (BigInt(fee) * 101n) / 100n;
    },
    [harvestContractInstance]
  );

  const calcFeeYield = useCallback(
    async (amountInWei) => {
      const fee = await yieldContractInstance.methods.calcFee(amountInWei).call();
      return (BigInt(fee) * 101n) / 100n;
    },
    [yieldContractInstance]
  );

  useEffect(() => {
    async function updateDepositFee() {
      const depositAmountInWei = numberToBN(Number(debouncedDepositAmount), 1e18);
      const predictedFee = await calcFee(depositAmountInWei);

      setDepositFee(web3.utils.fromWei(predictedFee.toString()));
    }
    async function updateDepositFeeGRO() {
      const depositAmountInWei = numberToBN(Number(debouncedDepositAmountGRO), 1e18);
      const predictedFee = await calcFeeYield(depositAmountInWei);
      setDepositFeeGRO(web3.utils.fromWei(predictedFee.toString()));
    }
    if (
      debouncedDepositAmount &&
      !isNaN(debouncedDepositAmount) &&
      Number(debouncedDepositAmount) > 0
    ) {
      updateDepositFee();
    }

    if (
      debouncedDepositAmountGRO &&
      !isNaN(debouncedDepositAmountGRO) &&
      Number(debouncedDepositAmountGRO) > 0
    ) {
      updateDepositFeeGRO();
    }
  }, [debouncedDepositAmount, debouncedDepositAmountGRO, calcFee, calcFeeYield, web3]);

  useEffect(() => {
    async function updateWithdrawFee() {
      const withdrawAmountInWei = numberToBN(Number(debouncedWithdrawAmount), 1e18);
      const predictedFee = await calcFee(withdrawAmountInWei);

      setWithdrawFee(web3.utils.fromWei(predictedFee.toString()));
    }

    async function updateWithdrawFeeGRO() {
      const withdrawAmountInWei = numberToBN(Number(debouncedWithdrawAmountGRO), 1e18);
      const predictedFee = await calcFeeYield(withdrawAmountInWei);

      setWithdrawFeeGRO(web3.utils.fromWei(predictedFee.toString()));
    }
    if (
      debouncedWithdrawAmount &&
      !isNaN(debouncedWithdrawAmount) &&
      Number(debouncedWithdrawAmount) > 0
    ) {
      updateWithdrawFee();
    }
    if (
      debouncedWithdrawAmountGRO &&
      !isNaN(debouncedWithdrawAmountGRO) &&
      Number(debouncedWithdrawAmountGRO) > 0
    ) {
      updateWithdrawFeeGRO();
    }
  }, [debouncedWithdrawAmount, debouncedWithdrawAmountGRO, calcFee, calcFeeYield, web3]);

  const onGRootDeposit = async () => {
    const depositAmountInWei = numberToBN(Number(depositAmount), 1e18);

    // check allowance
    const allowance = await gRootContractInstance.methods
      .allowance(address, GROOT_HARVEST_ADDRESS)
      .call();

    if (allowance < depositAmountInWei) {
      await gRootContractInstance.methods.approve(GROOT_HARVEST_ADDRESS, MAX_GROOT).send({
        from: address,
      });
    }

    // calculate fee first
    const predictedFee = await calcFee(depositAmountInWei);

    try {
      await harvestContractInstance.methods.depositBNB(depositAmountInWei).send({
        from: address,
        value: predictedFee.toString(),
      });

      addNotification({
        title: 'Success',
        message: `You have successfully deposited ${depositAmount} gROOT`,
        type: 'success',
      });
    } catch (err) {
      console.log(err);
      addNotification({
        title: 'Failed!',
        message: 'Deposit Failed. Please check if you have enough balance.',
        type: 'danger',
      });
    } finally {
      setLastUpdatedTime(Date.now());
    }
  };

  const onGRootWithdraw = async () => {
    const withdrawAmountInWei = numberToBN(Number(withdrawAmount), 1e18);
    const predictedFee = await calcFee(withdrawAmountInWei);
    try {
      await harvestContractInstance.methods.withdrawBNB(withdrawAmountInWei).send({
        from: address,
        value: predictedFee.toString(),
      });

      addNotification({
        title: 'Success',
        message: `You have successfully withdrawn ${withdrawAmount} gROOT`,
        type: 'success',
      });
    } catch (err) {
      console.log(err);
      addNotification({
        title: 'Failed!',
        message: 'Withdraw Failed. Please check if you have enough balance.',
        type: 'danger',
      });
    } finally {
      setLastUpdatedTime(Date.now());
    }
  };

  const onGRODeposit = async () => {
    const depositAmountInWei = numberToBN(Number(depositAmountGRO), 1e18);

    // check allowance
    const allowance = await groContractInstance.methods
      .allowance(address, GRO_YIELD_ADDRESS)
      .call();

    if (allowance < depositAmountInWei) {
      await groContractInstance.methods.approve(GRO_YIELD_ADDRESS, MAX_GROOT).send({
        from: address,
      });
    }

    // calculate fee first
    const predictedFee = await calcFeeYield(depositAmountInWei);

    try {
      await yieldContractInstance.methods.depositBNB(depositAmountInWei).send({
        from: address,
        value: predictedFee.toString(),
      });

      addNotification({
        title: 'Success',
        message: `You have successfully deposited ${depositAmountGRO} GRO`,
        type: 'success',
      });
    } catch (err) {
      console.log(err);
      addNotification({
        title: 'Failed!',
        message: 'Deposit Failed. Please check if you have enough balance.',
        type: 'danger',
      });
    } finally {
      setLastUpdatedTime(Date.now());
    }
  };

  const onGROWithdraw = async () => {
    const withdrawAmountInWei = numberToBN(Number(withdrawAmountGRO), 1e18);
    const predictedFee = await calcFeeYield(withdrawAmountInWei);
    try {
      await yieldContractInstance.methods.withdrawBNB(withdrawAmountInWei).send({
        from: address,
        value: predictedFee.toString(),
      });

      addNotification({
        title: 'Success',
        message: `You have successfully withdrawn ${withdrawAmountGRO} GRO`,
        type: 'success',
      });
    } catch (err) {
      console.log(err);
      addNotification({
        title: 'Failed!',
        message: 'Withdraw Failed. Please check if you have enough balance.',
        type: 'danger',
      });
    } finally {
      setLastUpdatedTime(Date.now());
    }
  };

  return {
    depositAmount,
    setDepositAmount,
    withdrawAmount,
    setWithdrawAmount,
    depositAmountGRO,
    setDepositAmountGRO,
    withdrawAmountGRO,
    setWithdrawAmountGRO,
    depositFee,
    withdrawFee,
    depositFeeGRO,
    withdrawFeeGRO,
    onGRootDeposit,
    onGRootWithdraw,
    onGRODeposit,
    onGROWithdraw,
  };
}
