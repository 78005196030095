import React from 'react';
import ReactDOM from 'react-dom';
import { Web3ReactProvider } from '@web3-react/core';
import { createGlobalStyle } from 'styled-components';
import { ThemeProvider } from 'theme-ui';
import { theme } from 'app/shared/util';
import { RefreshContextProvider } from './app/contexts/RefreshContext';
import MainApp from './MainApp';
import reportWebVitals from './reportWebVitals';
import { WalletContextProvider } from './app/contexts/WalletContext';

const getLibrary = (provider) => {
  return provider;
};

const GlobalStyle = createGlobalStyle`
  * {
    -webkit-font-smoothing: antialiased;
  }

  html {
    ${theme.mediaQuery.max.sm} {
      font-size: 14px;
    }
  }

  body {
    // padding-top: 72px;

    ${theme.mediaQuery.min.sm} {
      // padding-top: 100px;
    }
  }

  h1, h2, h3, h4, h5, h6, p {
    margin: 0;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: 600;
    line-height: 1.22;
  }

  h1 {
    ${theme.mediaQuery.max.sm} {
      font-size: 36px !important;
    }
  }

  h2 {
    ${theme.mediaQuery.max.sm} {
      font-size: 30px !important;
    }
  }

  h3 {
    ${theme.mediaQuery.max.sm} {
      font-size: 24px !important;
    }
  }

  a {
    text-decoration: none;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  input, button {
    font-family: inherit;
  }

  .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1055;
    display: flex !important;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 15px;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;

    ${theme.mediaQuery.min.md} {
      padding: 30px;
    }
  }

  .modal-dialog {
    position: relative;
    width: 100%;
    max-width: 700px;
    margin: auto;
    // pointer-events: none;
  }

  .modal-content {
    background-color: rgb(28, 28, 28);
    border: 1px solid gray;
    border-radius: 5px;
    color: #ffffff;
  }

  .modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px;
  }

  .modal-title {
    font-weight: 600;
    font-size: 24px;
    line-height: 1.1;
  }

  .modal-body {
    padding: 0 30px 30px;
  }
`;

ReactDOM.render(
  <React.StrictMode>
    <Web3ReactProvider getLibrary={getLibrary}>
      <RefreshContextProvider>
        <WalletContextProvider>
          <ThemeProvider theme={theme}>
            <GlobalStyle />
            <MainApp />
          </ThemeProvider>
        </WalletContextProvider>
      </RefreshContextProvider>
    </Web3ReactProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
