/* eslint-disable react/prop-types */
/** @jsxImportSource theme-ui */
import React from 'react';
import YouTube from 'react-youtube';
import styled from 'styled-components';
import Modal from '../Modal';
import { jsx } from 'theme-ui';

const VideoContainer = styled.div`
  position: relative;
  padding-bottom: 56%;

  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
  }
`;

const VideoModal = (props) => (
  <Modal show={props.show} onHide={props.onHide} title={props.title}>
    <VideoContainer>
      <YouTube
        opts={{
          playerVars: {
            autoplay: 1,
            rel: 0,
          },
        }}
        videoId={props.videoId}
      />
    </VideoContainer>
  </Modal>
);

export default VideoModal;
