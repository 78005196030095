import Web3 from 'web3';
import { getNodeUrl } from '@groot/contexts/getBSCNodeUrl';

export const getWeb3 = (library) => {
  return new Web3(
    // Replace YOUR-PROJECT-ID with a Project ID from your Infura Dashboard
    library || new Web3.providers.HttpProvider('https://main-light.eth.linkpool.io')
  );
};

export const getWeb3BSC = () => {
  return new Web3(
    // Replace YOUR-PROJECT-ID with a Project ID from your Infura Dashboard
    new Web3.providers.HttpProvider('https://bsc-dataseed1.ninicoin.io')
  );
};

export const getWeb3AVAX = () => {
  return new Web3(
    // Replace YOUR-PROJECT-ID with a Project ID from your Infura Dashboard
    new Web3.providers.HttpProvider('https://api.avax.network/ext/bc/C/rpc')
  );
};

export const getWeb3NoAccount = (chain) => {
  return new Web3(
    // Replace YOUR-PROJECT-ID with a Project ID from your Infura Dashboard
    new Web3.providers.HttpProvider(getNodeUrl(chain))
  );
};

export default getWeb3;
