import { useState, useEffect } from 'react';
import { BNtoNumber, getPancakeSwapReserves, getBnbToUSD, getWeb3 } from '@groot/shared/util';
import { GrootContractData } from '@groot/shared/data-access/contracts';
import { useWeb3React } from '@web3-react/core';
import { FETCH_INTERVAL } from '@groot/shared/consts/groot-ui-yield-consts';

const GROOT_HARVEST_ADDRESS = GrootContractData.global.smartContracts.GROOT_BNB_HARVEST.contract;

const GRO_YIELD_ADDRESS = GrootContractData.global.smartContracts.GRO_BNB_HARVEST.contract;
const GROOT_HARVEST_ABI = GrootContractData.global.smartContracts.GROOT_BNB_HARVEST.abi;

export default function useAPR(lastUpdatedTime) {
  const [userReserveGROOT, setUserReserveGROOT] = useState(0);
  const [userRewardGROOT, setUserRewardGROOT] = useState(0);
  const [userUSDReserveGROOT, setUserUSDReserveGROOT] = useState(0);
  const [totalReserveGROOT, setTotalReserveGROOT] = useState(0);
  const [totalUSDReserveGROOT, setTotalUSDReserveGROOT] = useState(0);
  const [totalReserveGRO, setTotalReserveGRO] = useState(0);
  const [totalUSDReserveGRO, setTotalUSDReserveGRO] = useState(0);
  const [userReserveGRO, setUserReserveGRO] = useState(0);
  const [userRewardGRO, setUserRewardGRO] = useState(0);
  const [userUSDReserveGRO, setUserUSDReserveGRO] = useState(0);
  const [userGrootRewardInUSD, setUserGrootRewardInUSD] = useState(0);
  const [userGRORewardInUSD, setUserGRORewardInUSD] = useState(0);

  const { account, library } = useWeb3React();
  const address = account;
  const web3 = getWeb3(library);

  const defaultDecimals = 1e18;

  useEffect(() => {
    async function getUserRerserveGroot() {
      const contractInstance = new web3.eth.Contract(GROOT_HARVEST_ABI, GROOT_HARVEST_ADDRESS);

      const totalReserve = await contractInstance.methods.totalReserve().call();
      const totalSupply = await contractInstance.methods.totalSupply().call();
      const userBalance = await contractInstance.methods.balanceOf(address).call();

      const finalResult =
        (Number(BNtoNumber(userBalance.toString(), defaultDecimals)) /
          Number(BNtoNumber(totalSupply.toString(), defaultDecimals))) *
        Number(BNtoNumber(totalReserve.toString(), defaultDecimals));

      setUserReserveGROOT(finalResult);

      const bnbToUSD = await getBnbToUSD(web3);
      const totalReserveGRoot = Number(BNtoNumber(totalReserve.toString(), defaultDecimals));

      const grootToBNB = await getGrootToBNB();
      setTotalReserveGROOT(totalReserveGRoot);
      setTotalUSDReserveGROOT(totalReserveGRoot * bnbToUSD * grootToBNB);
      return finalResult;
    }

    async function getUserRerserveGRO() {
      const contractInstance = new web3.eth.Contract(GROOT_HARVEST_ABI, GRO_YIELD_ADDRESS);

      const totalReserve = await contractInstance.methods.totalReserve().call();
      const totalSupply = await contractInstance.methods.totalSupply().call();
      const userBalance = await contractInstance.methods.balanceOf(address).call();

      const finalResult =
        (Number(BNtoNumber(userBalance.toString(), defaultDecimals)) /
          Number(BNtoNumber(totalSupply.toString(), defaultDecimals))) *
        Number(BNtoNumber(totalReserve.toString(), defaultDecimals));

      setUserReserveGRO(finalResult);

      const bnbToUSD = await getBnbToUSD(web3);
      const totalReserveGRO = Number(BNtoNumber(totalReserve.toString(), defaultDecimals));

      const groToBNB = await getGroToBNB();
      setTotalReserveGRO(totalReserveGRO);
      setTotalUSDReserveGRO(totalReserveGRO * bnbToUSD * groToBNB);
      return finalResult;
    }

    async function getUserRewardGroot() {
      const harvestContractInstance = new web3.eth.Contract(
        GROOT_HARVEST_ABI,
        GROOT_HARVEST_ADDRESS
      );
      const rewards = await harvestContractInstance.methods.unclaimedReward(address).call();

      const bnbToUSD = await getBnbToUSD(web3);
      const rewardInUSD = Number(BNtoNumber(rewards.toString(), defaultDecimals)) * bnbToUSD;

      setUserRewardGROOT(Number(BNtoNumber(rewards.toString(), defaultDecimals)));
      setUserGrootRewardInUSD(rewardInUSD);
    }

    async function getUserRewardGro() {
      const harvestContractInstance = new web3.eth.Contract(GROOT_HARVEST_ABI, GRO_YIELD_ADDRESS);
      const rewards = await harvestContractInstance.methods.unclaimedReward(address).call();

      const bnbToUSD = await getBnbToUSD(web3);
      const rewardInUSD = Number(BNtoNumber(rewards.toString(), defaultDecimals)) * bnbToUSD;

      setUserRewardGRO(Number(BNtoNumber(rewards.toString(), defaultDecimals)));
      setUserGRORewardInUSD(rewardInUSD);
    }

    async function getGrootToBNB() {
      const reserves = await getPancakeSwapReserves(web3, 'GROOT/BNB');
      const value = reserves[1] / reserves[0];
      return value;
    }

    async function getGroToBNB() {
      const reserves = await getPancakeSwapReserves(web3, 'GRO/BNB');
      const value = reserves[1] / reserves[0];
      return value;
    }

    async function getReserveUSDGroot() {
      const userGrootReserve = await getUserRerserveGroot();
      const bnbToUSD = await getBnbToUSD(web3);
      const grootToBNB = await getGrootToBNB();

      const finalResult = grootToBNB * userGrootReserve * bnbToUSD;
      setUserUSDReserveGROOT(finalResult);
    }

    async function getReserveUSDGRO() {
      const userGroReserve = await getUserRerserveGRO();
      const bnbToUSD = await getBnbToUSD(web3);
      const grootToBNB = await getGroToBNB();

      const finalResult = grootToBNB * userGroReserve * bnbToUSD;
      setUserUSDReserveGRO(finalResult);
    }

    const handler = setInterval(() => {
      if (address) {
        getReserveUSDGroot();
        getUserRewardGroot();
        getUserRewardGro();
        getReserveUSDGRO();
      }
    }, FETCH_INTERVAL);

    return () => {
      clearInterval(handler);
    };
  }, [address, web3, lastUpdatedTime]);

  return {
    userReserveGROOT,
    userRewardGROOT,
    userUSDReserveGROOT,
    userRewardGRO,
    userReserveGRO,
    userUSDReserveGRO,
    userGrootRewardInUSD,
    userGRORewardInUSD,
    totalReserveGROOT,
    totalUSDReserveGROOT,
    totalReserveGRO,
    totalUSDReserveGRO,
  };
}
