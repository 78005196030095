/** @jsxImportSource theme-ui */
import React from 'react';
import Container from '@groot/components/Container';
import { Button, Flex } from 'theme-ui';
import { StatsItem } from './components';
import { theme } from '@groot/shared/util';
import groCoinImage from './images/groCoinImage.png';
import { useGetGroPricePLS, useGetGroDataPLS } from '@groot/hooks/useGetGroPricePLS';

import {
  Hero,
  HeroContent,
  HeroObject,
  StatsSection,
  AssetDocumentationSection,
  AssetDocumentationContent,
} from './gro.styles';

function numberWithCommas(x) {
  return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

const Gro = () => {
  const groPrice = useGetGroPricePLS();
  const { groBurntPLS, groTotalSupplyPLS, groCirculationPLS } = useGetGroDataPLS();

  return (
    <>
      <Hero>
        <Container>
          <Flex
            sx={{ alignItems: 'center', justifyContent: 'space-between', flexFlow: 'row wrap' }}
          >
            <HeroContent>
              <h1 sx={{ ...theme.styles.h1, mb: 20 }}>GRO Token</h1>
              <p>
                GRO is the core token of the ecosystem and receives revenue from all Growth DeFi
                products, including xPERPS Protocol.
              </p>
              <br />
              <p>
                GRO is a low-supply token that has multiple buyback and burn mechanisms in place
                that make it deflationary.
              </p>
              <br />
              <p>
                <b>GRO Token:</b> 0x09e64c2b61a5f1690ee6fbed9baf5d6990f8dfd0 <br />
                <b>GrowthBTC:</b> 0x00febf86e8f0673f0feadac14b5ea1a05e744cb7 <br />
                <b>4WMM:</b> 0x8bf45680485b2ac15e452a9599e87b94c5a07792 <br />
                <b>Email:</b> admin@growthdefi.com
              </p>
            </HeroContent>
            <HeroObject>
              <img src={groCoinImage} alt="" style={{ width: '80%' }} />
            </HeroObject>
          </Flex>
        </Container>
      </Hero>
      <Container>
        <StatsSection>
          <StatsItem title="Price" value={`$${numberWithCommas(groPrice)}`} />
          <StatsItem title="Total Burned" value={numberWithCommas(groBurntPLS)} />
          <StatsItem title="Circulating supply" value={numberWithCommas(groCirculationPLS)} />
          <StatsItem title="Total supply" value={numberWithCommas(groTotalSupplyPLS)} />
          <StatsItem
            title="Marketcap"
            value={`$${numberWithCommas(groTotalSupplyPLS * groPrice)}`}
          />
        </StatsSection>
      </Container>

      <AssetDocumentationSection as="section">
        <Container>
          <AssetDocumentationContent>
            <h3 sx={{ ...theme.styles.h3, mb: 40 }}>
              Explore the Documentation to Learn How GRO Works Within the Growth DeFi Ecosystem
            </h3>
            <a href="/doc/Litepaper.pdf" target="_blank" rel="noreferrer">
              <Button variant="primary" sx={{ height: 50, pr: 30, pl: 30, fontSize: 16 }}>
                Asset Documentation
              </Button>
            </a>
          </AssetDocumentationContent>
        </Container>
      </AssetDocumentationSection>
    </>
  );
};

export default Gro;
