import React, { useContext } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useWeb3React } from '@web3-react/core';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { connectorLocalStorageKey, ConnectorNames, WalletContext } from '../contexts/WalletContext';
import METAMASK_ICON_URL from '../../assets/img/metamask.png';
import WALLETCONNECT_ICON_URL from '../../assets/img/walletConnectIcon.svg';
import styled from 'styled-components';
import useAuth from '../hooks/useAuth';
import useNotification from '../hooks/useNotification';

const SUPPORTED_WALLETS = [
  {
    label: 'MetaMask',
    icon: METAMASK_ICON_URL,
    connectorId: ConnectorNames.MetaMask,
    injected: true,
  },
  {
    label: 'WalletConnect',
    icon: WALLETCONNECT_ICON_URL,
    connectorId: ConnectorNames.WalletConnect,
    injected: false,
  },
];

const WalletCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  border-radius: 5px;
  background-color: rgb(44, 47, 54);
  border: 1px solid rgb(64, 68, 79);

  & + & {
    margin-top: 8px;
  }

  &:hover {
    cursor: pointer;
    border: 1px solid white;
  }

  img {
    width: 24px;
    height: 24px;
  }
`;

const AccountActions = styled.div`
  display: flex;
  align-items: center;
  margin-top: 12px;

  a {
    color: white;
  }
`;

const CopyWrapper = styled.span`
  cursor: pointer;
`;

const StyledModal = styled(Modal)`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  justify-content: center;
  align-items: center;
  display: flex !important;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  background-color: rgba(0, 0, 0, 0.5);

  .modal-dialog {
    position: relative;
    width: 100%;
    max-width: 500px;
    pointer-events: none;
  }

  .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: rgb(19, 19, 19);
    background-clip: padding-box;
    border: 1px solid gray;
    border-radius: 5px;
    outline: 0;
  }

  .modal-footer {
    display: flex;
    flex-wrap: wrap;
    flex-shrink: 0;
    align-items: center;
    justify-content: flex-end;
    padding: 0.75rem;
    border: none;
    border-bottom-right-radius: calc(0.3rem - 1px);
    border-bottom-left-radius: calc(0.3rem - 1px);
  }

  .close {
    background: none;
    border: none;
    font-size: 42px;
    line-height: 20px;
    color: #ffffff;
    cursor: pointer;
  }
`;

const StyledModalHeader = styled(Modal.Header)`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 30px;
  border: none;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
`;

const StyledModalTitle = styled(Modal.Title)`
  font-weight: 600;
  font-size: 24px;
  line-height: 1;
`;

const StyledModalBody = styled(Modal.Body)`
  position: relative;
  flex: 1 1 auto;
  padding: 0 30px 30px;
`;

const ProviderOptions = () => {
  const { login } = useAuth();
  const { setShowWalletModal } = useContext(WalletContext);

  const wallets = SUPPORTED_WALLETS.filter((option) => {
    if (option.injected) {
      return window.ethereum ? true : false;
    }

    return true;
  });

  return (
    <div>
      {wallets.map(({ label, icon, connectorId }) => {
        return (
          <WalletCard
            key={label}
            onClick={() => {
              login(connectorId);
              window.localStorage.setItem(connectorLocalStorageKey, connectorId);
              setShowWalletModal(false);
            }}
          >
            {label}
            <img src={icon} alt="option" />
          </WalletCard>
        );
      })}
    </div>
  );
};

const AccountInformation = () => {
  const { account, chainId } = useWeb3React();
  const { addNotification } = useNotification();
  const { logout } = useAuth();
  const { setShowWalletModal } = useContext(WalletContext);

  return (
    <div>
      {account}
      <AccountActions>
        <CopyToClipboard
          text={account}
          onCopy={() => {
            addNotification({
              title: '',
              message: 'Copied address to clipboard!',
              type: 'success',
            });
          }}
        >
          <CopyWrapper>
            <i className="fa fa-clipboard" style={{ marginRight: 8 }} />
            Copy Address
          </CopyWrapper>
        </CopyToClipboard>
        <a
          target="_blank"
          rel="noreferrer noopener"
          href={`https://${chainId === 1 ? 'etherscan.io' : 'bscscan.com'}/address/${account}`}
        >
          <i className="fa fa-external-link" style={{ marginLeft: 24, marginRight: 8 }} />
          View on {chainId === 1 ? 'Etherscan' : 'Bscscan'}
        </a>
      </AccountActions>
      <div>
        <Button
          variant="outline-success"
          type="button"
          style={{ margin: '24px auto 0' }}
          onClick={() => {
            logout();
            window.localStorage.removeItem(connectorLocalStorageKey);
            setShowWalletModal(false);
          }}
        >
          Logout
        </Button>
      </div>
    </div>
  );
};

export default function WalletModal() {
  const { showWalletModal, setShowWalletModal } = useContext(WalletContext);
  const { account } = useWeb3React();

  return (
    <StyledModal
      show={showWalletModal}
      onHide={() => setShowWalletModal(false)}
      backdrop="static"
      keyboard={false}
      className="info-modal"
      centered
    >
      <StyledModalHeader closeButton>
        <StyledModalTitle>{account ? 'Your wallet' : 'Connect to a wallet'}</StyledModalTitle>
      </StyledModalHeader>
      <StyledModalBody>
        {!account && <ProviderOptions />}
        {account && <AccountInformation />}
      </StyledModalBody>
    </StyledModal>
  );
}
