/** @jsxImportSource theme-ui */
import React from 'react';
import styled from 'styled-components';
import Container from '@groot/components/Container';
import { theme } from '@groot/shared/util';

import growthDefiLogo from './images/GrowthDefiLogo.png';
import olympusLogo from './images/casino-launch-banner.png';
import xIcon from './images/xIcon.svg';
import Counter from '../Counter';

const Banner = styled.div`
  position: relative;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px 15px;
  background: transparent;
`;

const BannerObject = styled.div`
  ${theme.mediaQuery.max.sm} {
    margin-top: 16px;
  }

  ${theme.mediaQuery.min.sm} {
    margin-left: 32px;
  }

  ${theme.mediaQuery.min.md} {
    margin-left: 64px;
  }
`;

const OlympusLogo = styled.img`
  width: 80%;
  border-radius: 5px;
`;

const Button = styled.a`
  position: relative;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  padding: 8px 24px;
  background: rgba(255, 255, 255, 0.282);
  backdrop-filter: blur(27.1828px);
  border-radius: 5px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 15px;
  color: rgb(255, 255, 255);
  cursor: pointer;
`;

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${theme.mediaQuery.max.sm} {
    flex-direction: column;
  }
`;

// eslint-disable-next-line react/prop-types
const CasinoBanner = ({ children, href, ...props }) => (
  <a href="https://casino.growthdefi.com/" target={'_blank'} rel="noreferrer">
    <Banner>
      <OlympusLogo src={olympusLogo} />
      <Counter />
    </Banner>
  </a>
);

export default CasinoBanner;
