/** @jsxImportSource theme-ui */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { theme } from 'app/shared/util';

const Container = styled.article`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 120px;
  margin-top: 8px;
  margin-bottom: 8px;
  border-radius: 5px;
  background-color: #1c1c1c;
  text-align: center;

  ${theme.mediaQuery.min.sm} {
    width: calc((100% / 3) - 30px);
    height: 175px;
    margin: 15px;
  }
`;

const Label = styled.label`
  font-weight: 600;
  font-size: 20px;
  line-height: 1.2;
  color: #9c9c9c;
`;

const Value = styled.div`
  margin-bottom: 10px;

  ${theme.mediaQuery.max.sm} {
    font-size: 36px !important;
  }
`;

const StatsItem = ({ title, value }) => (
  <Container>
    <Value sx={{ ...theme.styles.h1 }}>{value}</Value>
    <Label>{title}</Label>
  </Container>
);

export default StatsItem;

StatsItem.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
};
