import { useMemo, useContext } from 'react';
import { GrootContractData } from '@groot/shared/data-access/contracts';
import { useWeb3React } from '@web3-react/core';
import { getWeb3 } from '@groot/shared/util';
import GrootUiYieldContext from '@groot/shared/context/groot-ui-yield-context';
import useNotification from '@groot/hooks/useNotification';

const GROOT_HARVEST_ADDRESS = GrootContractData.global.smartContracts.GROOT_BNB_HARVEST.contract;
const GROOT_HARVEST_ABI = GrootContractData.global.smartContracts.GROOT_BNB_HARVEST.abi;
const GRO_HARVEST_ADDRESS = GrootContractData.global.smartContracts.GRO_BNB_HARVEST.contract;
const GRO_HARVEST_ABI = GrootContractData.global.smartContracts.GRO_BNB_HARVEST.abi;
const GHARVEST_HELPER_ID = GrootContractData.global.smartContracts.GHARVEST_TOKEN_HELPER.contract;
const GHARVEST_HELPER_ABI = GrootContractData.global.smartContracts.GHARVEST_TOKEN_HELPER.abi;
const GROOT_TOKEN_ADDRESS = GrootContractData.global.tokens.gROOT.contract;
const GRO_TOKEN_ADDRESS = GrootContractData.global.tokens.GRO.contract;

export default function useGRootHarvest2() {
  const { setLastUpdatedTime } = useContext(GrootUiYieldContext);
  const { addNotification } = useNotification();

  const { account, library } = useWeb3React();
  const address = account;
  const web3 = getWeb3(library);

  const harvestContractInstance = useMemo(
    () => new web3.eth.Contract(GROOT_HARVEST_ABI, GROOT_HARVEST_ADDRESS),
    [web3]
  );

  const yieldContractInstance = useMemo(
    () => new web3.eth.Contract(GRO_HARVEST_ABI, GRO_HARVEST_ADDRESS),
    [web3]
  );

  const gHarvestHelperContractInstance = useMemo(
    () => new web3.eth.Contract(GHARVEST_HELPER_ABI, GHARVEST_HELPER_ID),
    [web3]
  );

  const onGRootClaimBNB = async () => {
    try {
      await harvestContractInstance.methods
        .claimBNB()
        .send({
          from: address,
        })
        .on('transactionHash', (hash) => {
          // eslint-disable-next-line no-console
          console.log('hash', hash);
        })
        .on('receipt', () => {})
        .on('confirmation', async () => {})
        .on('error', async () => {});

      addNotification({
        title: 'Success',
        message: `You have successfully deposited claimed your rewards. `,
        type: 'success',
      });
    } catch (err) {
      console.log(err);
      addNotification({
        title: 'Failed!',
        message: 'Claim Failed. Please try again.',
        type: 'danger',
      });
    } finally {
      setLastUpdatedTime(Date.now());
    }
  };

  const onGRootCompoundBNB = async () => {
    const reward = await harvestContractInstance.methods.unclaimedReward(address).call();

    try {
      await harvestContractInstance.methods
        .claimBNB()
        .send({
          from: address,
        })
        .on('transactionHash', (hash) => {
          // eslint-disable-next-line no-console
          console.log('hash', hash);
        })
        .on('receipt', () => {})
        .on('confirmation', async () => {})
        .on('error', async () => {});

      await gHarvestHelperContractInstance.methods
        .depositFeeOnlyBNB(GROOT_HARVEST_ADDRESS)
        .send({ from: address, value: reward })
        .on('transactionHash', (hash) => {
          // eslint-disable-next-line no-console
          console.log('hash', hash);
        })
        .on('receipt', () => {})
        .on('confirmation', async () => {})
        .on('error', async () => {});

      addNotification({
        title: 'Success',
        message: `You have successfully deposited claimed your rewards. `,
        type: 'success',
      });
    } catch (err) {
      console.log(err);
      addNotification({
        title: 'Failed!',
        message: 'Compound Failed. Please try again.',
        type: 'danger',
      });
    } finally {
      setLastUpdatedTime(Date.now());
    }
  };

  const onGROClaimBNB = async () => {
    try {
      await yieldContractInstance.methods
        .claimBNB()
        .send({
          from: address,
        })
        .on('transactionHash', (hash) => {
          // eslint-disable-next-line no-console
          console.log('hash', hash);
        })
        .on('receipt', () => {})
        .on('confirmation', async () => {})
        .on('error', async () => {});

      addNotification({
        title: 'Success',
        message: `You have successfully deposited claimed your rewards. `,
        type: 'success',
      });
    } catch (err) {
      console.log(err);
      addNotification({
        title: 'Failed!',
        message: 'Claim Failed. Please try again.',
        type: 'danger',
      });
    } finally {
      setLastUpdatedTime(Date.now());
    }
  };

  const onGROCompoundBNB = async () => {
    const reward = await yieldContractInstance.methods.unclaimedReward(address).call();

    try {
      await yieldContractInstance.methods
        .claimBNB()
        .send({
          from: address,
        })
        .on('transactionHash', (hash) => {
          // eslint-disable-next-line no-console
          console.log('hash', hash);
        })
        .on('receipt', () => {})
        .on('confirmation', async () => {})
        .on('error', async () => {});

      await gHarvestHelperContractInstance.methods
        .depositFeeOnlyBNB(GRO_HARVEST_ADDRESS)
        .send({ from: address, value: reward })
        .on('transactionHash', (hash) => {
          // eslint-disable-next-line no-console
          console.log('hash', hash);
        })
        .on('receipt', () => {})
        .on('confirmation', async () => {})
        .on('error', async () => {});

      addNotification({
        title: 'Success',
        message: `You have successfully deposited claimed your rewards. `,
        type: 'success',
      });
    } catch (err) {
      console.log(err);
      addNotification({
        title: 'Failed!',
        message: 'Compound Failed. Please try again.',
        type: 'danger',
      });
    } finally {
      setLastUpdatedTime(Date.now());
    }
  };

  return { onGRootClaimBNB, onGRootCompoundBNB, onGROClaimBNB, onGROCompoundBNB };
}
