import { useRef, useState, useEffect, useMemo, useCallback } from 'react';
import { getWeb3, getWeb3AVAX, getWeb3BSC, getWeb3NoAccount } from '@groot/shared/util';
import useDebounce from '@groot/shared/hooks/useDebounce';
import { BNtoNumber, numberToBN } from '@groot/utils/global.data';
import { useWeb3React } from '@web3-react/core';
import Tokens from '../../../consts/tokens';
import ERC20 from '../../../consts/abi/erc20.json';
import Panel_ABI from '../../../consts/abi/panel.json';
import Contracts from '@groot/consts/contracts';
import useNotification from '../../../hooks/useNotification';
import TRUSTED from '../../../consts/abi/trusted_abi.json';
import useRefresh from '@groot/hooks/useRefresh';
import { getAddress } from '@groot/utils/addressHelper';
import { setupNetwork } from '@groot/utils/wallet';
import Web3 from 'web3';
export const FETCH_INTERVAL = 5000;

const idToChainName = {
  56: 'BSC',
  250: 'FTM',
  43114: 'AVAX',
};

const chainConfigs = {
  AVAX: 43114,
  BSC: 56,
  FTM: 250,
};

export function useGetGroBridge() {
  const { addNotification } = useNotification();
  const { account, chainId } = useWeb3React();
  const [fromChain, setFromChain] = useState('BSC');
  const [toChain, setToChain] = useState('AVAX');
  const [balance, setBalance] = useState(0);
  const [approvedAllowance, setApprovedAllowance] = useState(0);
  const [availableGRO, setAvailableGRO] = useState(0);
  const [input, setInput] = useState('');
  const [receive, setReceive] = useState('');
  const { fastRefresh } = useRefresh();

  const onSelectToChain = (chain) => {
    setToChain(chain);
  };

  const onSelectFromChain = (chain) => {
    setFromChain(chain);
    setupNetwork(chainConfigs[chain]);
  };

  const panelContract = useMemo(() => {
    const web3 = getWeb3NoAccount(chainId);
    const panelAddress = getAddress(Contracts.PANEL_GRO, chainId);

    return new web3.eth.Contract(Panel_ABI, panelAddress);
  }, [chainId]);

  const tokenContract = useMemo(() => {
    const web3 = getWeb3NoAccount(chainId);
    const tokenAddress = getAddress(Tokens.GRO, chainId);
    return new web3.eth.Contract(ERC20, tokenAddress);
  }, [chainId]);

  const availableGROContract = useMemo(() => {
    const tempWeb3 = getWeb3NoAccount(chainId);
    const tokenAddress = getAddress(Tokens.GRO, chainId);
    return new tempWeb3.eth.Contract(ERC20, tokenAddress);
  }, [chainId]);

  const unlockTokens = useCallback(async () => {
    try {
      const web3 = new Web3(window.ethereum);
      const _amount = numberToBN(1000000000, 1e18);
      const contractAddress = getAddress(Tokens.GRO, chainId);
      const spender = getAddress(Contracts.TRUSTED_GRO, chainId);

      const contract = new web3.eth.Contract(ERC20, contractAddress);
      await contract.methods.approve(spender, _amount).send({ from: account });

      addNotification({
        title: 'Transaction Completed',
        message: 'Successfully approved.',
        type: 'success',
      });
    } catch {
      addNotification({
        title: 'Transaction Failed',
        message: 'An error occurred during the transaction.  Please try again later.',
        type: 'danger',
      });
    }
  }, [account, chainId, addNotification]);

  const swapTokens = useCallback(async () => {
    try {
      const web3 = new Web3(window.ethereum);
      const amount = parseFloat(input);
      const contractAddress = getAddress(Contracts.TRUSTED_GRO, chainId);

      const contract = new web3.eth.Contract(TRUSTED, contractAddress);

      if (amount < 5) {
        addNotification({
          title: 'Swap Failed',
          message: 'The amount swapped must be greater than or equivalent to 5 GRO.',
          type: 'danger',
        });
        return;
      }
      if (availableGRO === 0) {
        addNotification({
          title: 'Swap Failed',
          message: 'There are not enough tokens in the operator contract.',
          type: 'danger',
        });
        return;
      }

      const data = await panelContract.methods
        .calcDepositParams(account, numberToBN(amount, 1e18), chainConfigs[toChain])
        .call();

      const {
        _targetBridge,
        _targetChainId,
        _server,
        _sourceAmount,
        _targetAmount,
        _timestamp,
        _transferId,
      } = data;

      if (parseFloat(BNtoNumber(_targetAmount, 1e18)) > availableGRO) {
        addNotification({
          title: 'Swap Failed',
          message: 'There are not enough tokens in the operator contract.',
          type: 'danger',
        });
        return;
      }

      await contract.methods
        .deposit(
          _targetBridge,
          _targetChainId,
          _server,
          _sourceAmount,
          _targetAmount,
          _timestamp,
          _transferId
        )
        .send({ from: account });

      addNotification({
        title: 'Transaction Completed',
        message:
          'Swaps can take on average between 30-45 minutes to complete. If gas prices are very high on the target chain it may take longer.Ensure your wallet is connected to the correct network that you wish to swap from (e.g. if swapping from BSC > Avalanche, then connect to BSC).',
        type: 'success',
      });
    } catch {
      addNotification({
        title: 'Transaction Failed',
        message: 'An error occurred during the transaction.  Please try again later.',
        type: 'danger',
      });
    }
  }, [chainId, account, input, addNotification, panelContract, availableGRO, toChain]);

  const onChangeInput = (e) => {
    const amount = e.target.value === '' ? 0 : parseFloat(e.target.value);
    setInput(amount.toString());
  };

  useEffect(() => {
    async function getBalance() {
      try {
        const web3 = getWeb3NoAccount(chainId);
        const instance = new web3.eth.Contract(ERC20, getAddress(Tokens.GRO, chainId));
        const spender = getAddress(Contracts.TRUSTED_GRO, chainId);
        const newBalance = await instance.methods.balanceOf(account).call();
        const allowance = await instance.methods.allowance(account, spender).call();

        setBalance(Number(web3.utils.fromWei(newBalance)));
        setApprovedAllowance(Number(web3.utils.fromWei(allowance)));
      } catch (err) {
        console.log(err);
      }
    }

    async function getAvailableGRO() {
      try {
        const chain = chainConfigs[toChain];
        const web3 = getWeb3NoAccount(chain);
        const operatorAddress = getAddress(Contracts.OPERATOR_GRO, chain);
        const instance = new web3.eth.Contract(ERC20, getAddress(Tokens.GRO, chain));
        const bal = await instance.methods.balanceOf(operatorAddress).call();
        setAvailableGRO(Number(web3.utils.fromWei(bal)));
      } catch (err) {
        console.log(err);
      }
    }

    if (account) {
      getBalance();
    }
    if (chainId) {
      getAvailableGRO();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, chainId, fastRefresh, toChain]);

  const setMax = () => {
    setInput(balance);
  };

  const debouncedInput = useDebounce(input);

  useEffect(() => {
    const getDepositParams = async (amount) => {
      const _amount = numberToBN(amount, 1e18);
      const _chainId = chainConfigs[toChain];
      const data = await panelContract.methods.calcDepositParams(account, _amount, _chainId).call();

      return data;
    };

    async function getTargetAmount() {
      try {
        const { _targetAmount } = await getDepositParams(debouncedInput);
        setReceive(parseFloat(BNtoNumber(_targetAmount.toString(), 1e18)).toFixed(6));
      } catch {
        setReceive('0.000000');
      }
    }

    getTargetAmount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedInput, chainId, account, panelContract, toChain]);

  useEffect(() => {
    if (chainId) {
      setFromChain(idToChainName[chainId]);
    }
  }, [chainId]);

  return {
    groBalance: balance,
    groAllowance: approvedAllowance,
    groInput: input,
    groReceive: receive,
    groOnChangeInput: onChangeInput,
    groUnlock: unlockTokens,
    groSwap: swapTokens,
    groSetMax: setMax,
    groAvailable: availableGRO,
    fromChain,
    toChain,
    onSelectToChain,
    onSelectFromChain,
  };
}

export const useGetChainData = () => {
  const [buyGroLink, setBuyGroLink] = useState(
    'https://app.apeswap.finance/swap?inputCurrency=ETH&outputCurrency=0x336eD56D8615271b38EcEE6F4786B55d0EE91b96'
  );
  const stakeGroLink = 'https://wheat.growthdefi.com/vaults';
  const { chainId } = useWeb3React();

  useEffect(() => {
    if (chainId === 43114) {
      setBuyGroLink(
        'https://traderjoexyz.com/#/trade?outputCurrency=0x72699ba15CC734F8db874fa9652c8DE12093F187'
      );
    } else if (chainId === 250) {
      setBuyGroLink(
        'https://spookyswap.finance/swap?inputCurrency=FTM&outputCurrency=0x91F1430833879272643658f8eD07d60257dDf321'
      );
    } else {
      setBuyGroLink(
        'https://app.apeswap.finance/swap?inputCurrency=ETH&outputCurrency=0x336eD56D8615271b38EcEE6F4786B55d0EE91b96'
      );
    }
  }, [chainId]);

  return { buyGroLink, stakeGroLink };
};
