const breakpoints = {
  xs: 576,
  sm: 768,
  md: 992,
  lg: 1200,
};

export default {
  buttons: {
    primary: {
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: 40,
      padding: '8px 24px',
      background:
        'linear-gradient(137.66deg, rgb(128, 64, 232) -10.17%, rgb(107, 64, 232) 109.35%)',
      border: 'none',
      borderRadius: '5px',
      fontWeight: 600,
      fontFamily: 'Montserrat',
      fontSize: '14px',
      lineHeight: 1,
      color: 'text',
      cursor: 'pointer',
    },
    secondary: {
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: 40,
      padding: '8px 24px',
      background: 'rgba(255, 255, 255, 0.28308) !important',
      backdropFilter: 'blur(27.1828px)',
      border: 'none',
      borderRadius: '5px',
      fontWeight: 600,
      fontFamily: 'Montserrat',
      fontSize: '14px',
      lineHeight: 1,
      color: 'text',
      cursor: 'pointer',
    },
  },
  fonts: {
    body: 'Montserrat, "Helvetica Neue", sans-serif',
    heading: 'inherit',
    monospace: 'Menlo, monospace',
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 96],
  fontWeights: {
    body: 400,
    heading: 600,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.22,
  },
  colors: {
    text: '#ffffff',
    background: '#141414',
    primary: '#07c',
    secondary: '#30c',
    muted: '#f6f6f6',
  },
  sizes: {
    container: '1260px',
  },
  styles: {
    root: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body',
    },
    h1: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 600,
      fontSize: 50,
    },
    h2: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 600,
      fontSize: 40,
    },
    h3: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 600,
      fontSize: 30,
    },
    h4: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 600,
      fontSize: 2,
    },
    h5: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 600,
      fontSize: 1,
    },
    h6: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 600,
      fontSize: 0,
    },
    p: {
      margin: 0,
      fontFamily: 'body',
      fontWeight: 'body',
      lineHeight: 'body',
      color: 'text',
    },
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
    a: {
      color: 'primary',
    },
    pre: {
      fontFamily: 'monospace',
      overflowX: 'auto',
      code: {
        color: 'inherit',
      },
    },
    code: {
      fontFamily: 'monospace',
      fontSize: 'inherit',
    },
    table: {
      width: '100%',
      borderCollapse: 'separate',
      borderSpacing: 0,
    },
    th: {
      textAlign: 'left',
      borderBottomStyle: 'solid',
    },
    td: {
      textAlign: 'left',
      borderBottomStyle: 'solid',
    },
    img: {
      maxWidth: '100%',
    },
  },
  mediaQuery: {
    max: {
      xs: `@media screen and (max-width: ${breakpoints.xs - 1}px)`,
      sm: `@media screen and (max-width: ${breakpoints.sm - 1}px)`,
      md: `@media screen and (max-width: ${breakpoints.md - 1}px)`,
      lg: `@media screen and (max-width: ${breakpoints.lg - 1}px)`,
    },
    min: {
      xs: `@media screen and (min-width: ${breakpoints.xs}px)`,
      sm: `@media screen and (min-width: ${breakpoints.sm}px)`,
      md: `@media screen and (min-width: ${breakpoints.md}px)`,
      lg: `@media screen and (min-width: ${breakpoints.lg}px)`,
    },
  },
};
