/* eslint-disable react/prop-types */
/** @jsxImportSource theme-ui */
import React from 'react';
import styled from 'styled-components';
import ReactModal from 'react-bootstrap/Modal';
import { jsx } from 'theme-ui';
import { ReactComponent as CloseIcon } from '@groot/components/NewHeader/images/CloseIcon.svg';

const CloseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin: -12px 0;
  padding: 0;
  background: none;
  border: none;

  svg {
    width: 14px;
  }
`;

const Modal = (props) => (
  <ReactModal show={props.show} onHide={props.onHide} backdrop="static" keyboard={false}>
    <ReactModal.Header>
      <ReactModal.Title>{props.title}</ReactModal.Title>
      <CloseButton onClick={props.onHide}>
        <CloseIcon />
      </CloseButton>
    </ReactModal.Header>
    <ReactModal.Body>{props.children}</ReactModal.Body>
  </ReactModal>
);

export default Modal;
