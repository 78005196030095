import { useState, useEffect } from 'react';
import { useWeb3React } from '@web3-react/core';

import { GrootContractData } from '@groot/shared/data-access/contracts';
import { getWeb3, BNtoNumber, getPancakeSwapReserves } from '@groot/shared/util';

const GROOT_HARVEST_ADDRESS = GrootContractData.global.smartContracts.GROOT_BNB_HARVEST.contract;
const GROOT_HARVEST_ABI = GrootContractData.global.smartContracts.GROOT_BNB_HARVEST.abi;

const GRO_HARVEST_ADDRESS = GrootContractData.global.smartContracts.GRO_BNB_HARVEST.contract;
const GRO_HARVEST_ABI = GrootContractData.global.smartContracts.GRO_BNB_HARVEST.abi;

export default function useAPR(lastUpdatedTime) {
  const [gROOTAPR, setgROOTAPR] = useState(0);
  const [groAPR, setGROAPR] = useState(0);

  const { account, library } = useWeb3React();
  const address = account;
  const web3 = getWeb3(library);

  const defaultDecimals = 1e18;

  useEffect(() => {
    async function getDistributedBNB() {
      const contractInstance = new web3.eth.Contract(GROOT_HARVEST_ABI, GROOT_HARVEST_ADDRESS);

      const result = await contractInstance.methods.rewardPerBlock().call();

      const finalResult = Number(BNtoNumber(result.toString(), defaultDecimals)) * 10512000;

      return finalResult;
    }
    async function getGRODistributedBNB() {
      const contractInstance = new web3.eth.Contract(GRO_HARVEST_ABI, GRO_HARVEST_ADDRESS);

      const result = await contractInstance.methods.rewardPerBlock().call();

      const finalResult = Number(BNtoNumber(result.toString(), defaultDecimals)) * 10512000;

      return finalResult;
    }

    async function getgROOTPrice() {
      const harvestContractInstance = new web3.eth.Contract(
        GROOT_HARVEST_ABI,
        GROOT_HARVEST_ADDRESS
      );
      const totalReserve = await harvestContractInstance.methods.totalReserve().call();
      const reserves = await getPancakeSwapReserves(web3, 'GROOT/BNB');
      const value = reserves[1] / reserves[0];

      return value * Number(BNtoNumber(totalReserve.toString(), defaultDecimals));
    }

    async function getGROPrice() {
      const harvestContractInstance = new web3.eth.Contract(GRO_HARVEST_ABI, GRO_HARVEST_ADDRESS);
      const totalReserve = await harvestContractInstance.methods.totalReserve().call();
      const reserves = await getPancakeSwapReserves(web3, 'GRO/BNB');
      const value = reserves[1] / reserves[0];

      return value * Number(BNtoNumber(totalReserve.toString(), defaultDecimals));
    }

    async function getAPR() {
      const rewardsPerYear = await getDistributedBNB();
      const grootBNBValue = await getgROOTPrice();
      let groot_apy;

      try {
        if (grootBNBValue === 0) {
          throw new Error('Value cannot be zero. ');
        }
        const groot_apr = rewardsPerYear / grootBNBValue;

        const apy = Math.E ** groot_apr - 1;

        groot_apy = apy * 100;
      } catch {
        groot_apy = 0;
      }

      setgROOTAPR(groot_apy);
    }

    async function getAPRGro() {
      const rewardsPerYear = await getGRODistributedBNB();
      const groBNBValue = await getGROPrice();
      let gro_apy;

      try {
        if (groBNBValue === 0) {
          throw new Error('Value cannot be zero. ');
        }
        const gro_apr = rewardsPerYear / groBNBValue;

        const apy = Math.E ** gro_apr - 1;

        gro_apy = apy * 100;
      } catch {
        gro_apy = 0;
      }

      setGROAPR(gro_apy);
    }

    if (address) {
      getAPR();
      getAPRGro();
    }
  }, [address, web3, lastUpdatedTime]);

  return { gROOTAPR, groAPR };
}
