import styled from 'styled-components';
import { Flex } from 'theme-ui';
import { theme } from '@groot/shared/util';
import FeatureCard from './components/FeatureCard';
import bgImage from './images/BgPattern.png';

export const BgImage = styled.div`
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  background-image: url(${bgImage});
  background-position: top right;
  background-repeat: no-repeat;
  background-size: 52%;

  ${theme.mediaQuery.min.sm} {
    height: 1100px;
  }
`;

export const Hero = styled.section`
  padding: 60px 0 80px;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: bottom center;

  ${theme.mediaQuery.max.sm} {
    text-align: center;
  }

  ${theme.mediaQuery.min.sm} {
    margin-bottom: 62px;
    padding: 62px 0;
  }
`;

export const HeroButtons = styled.div`
  display: flex;
  flex-direction: column;

  ${theme.mediaQuery.min.sm} {
    flex-direction: row;
    align-items: center;
  }
`;

export const HeroContent = styled.article`
  width: 100%;

  ${theme.mediaQuery.min.sm} {
    width: 50%;
    max-width: 570px;
    margin-top: 42px;
    padding-right: 15px;
  }

  p {
    color: #9c9c9c;

    ${theme.mediaQuery.max.sm} {
      margin-bottom: 20px;
    }
  }

  ${HeroButtons} {
    margin-top: 34px;

    ${theme.mediaQuery.max.sm} {
      display: none;
    }
  }
`;

export const HeroObject = styled.article`
  width: 100%;

  ${theme.mediaQuery.min.sm} {
    width: 50%;
    max-width: 540px;
    padding-left: 15px;
  }

  ${HeroButtons} {
    margin-top: 15px;

    ${theme.mediaQuery.min.sm} {
      display: none;
    }
  }
`;

export const HeroButton = styled.a`
  width: 100%;

  ${theme.mediaQuery.min.sm} {
    width: auto;
  }

  & + & {
    margin: 16px 0 0;

    ${theme.mediaQuery.min.sm} {
      margin: 0 0 0 20px;
    }
  }
`;

export const FeatureSection = styled(Flex)`
  justify-content: space-between;
  flex-flow: row wrap;
  margin-bottom: 80px !important;

  ${theme.mediaQuery.min.sm} {
    margin-bottom: 120px !important;
  }
`;

export const SecureSection = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  margin-bottom: 80px !important;

  ${theme.mediaQuery.max.sm} {
    flex-direction: column;
  }

  ${theme.mediaQuery.min.sm} {
    margin-bottom: 120px !important;
  }
`;

export const SecureContent = styled.article`
  flex-shrink: 0;
  width: 100%;

  ${theme.mediaQuery.max.sm} {
    text-align: center;
  }

  ${theme.mediaQuery.min.sm} {
    max-width: 480px;
  }
`;

export const SecureObject = styled.article`
  ${theme.mediaQuery.max.sm} {
    margin-top: 40px;

    svg {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  ${theme.mediaQuery.min.sm} {
    width: calc(100% - 480px);

    svg {
      float: left;
    }
  }
`;

export const LoansSection = styled(Flex)`
  margin-bottom: 80px !important;

  ${theme.mediaQuery.max.sm} {
    flex-direction: column;
  }

  ${theme.mediaQuery.min.sm} {
    margin-bottom: 120px !important;
  }
`;

export const LoansContent = styled.article`
  order: 0;
  flex-shrink: 0;
  width: 100%;

  ${theme.mediaQuery.max.sm} {
    text-align: center;
  }

  ${theme.mediaQuery.min.sm} {
    order: 1;
    max-width: 480px;
  }
`;

export const LoansObject = styled.article`
  ${theme.mediaQuery.max.sm} {
    order: 1;
    margin-top: 40px;

    svg {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  ${theme.mediaQuery.min.sm} {
    order: 0;
    width: calc(100% - 480px);

    svg {
      float: right;
    }
  }
`;

export const LoansButtons = styled(Flex)`
  ${theme.mediaQuery.max.sm} {
    flex-direction: column;
  }
`;

export const LoanButton = styled.a`
  width: 100%;

  & + & {
    margin: 15px 0 0;
  }

  ${theme.mediaQuery.min.sm} {
    width: 156px;

    & + & {
      margin: 0 0 0 20px;
    }
  }
`;

export const InvestFeatureCard = styled(FeatureCard)`
  ${theme.mediaQuery.min.sm} {
    min-height: 355px;

    &:nth-child(4) {
      margin-top: 30px !important;
      margin-right: 15px !important;
      margin-left: auto !important;
    }

    &:nth-child(5) {
      margin-top: 30px !important;
      margin-right: auto !important;
      margin-left: 15px !important;
    }
  }
`;

export const WhyToInvestSection = styled.section`
  max-width: 730px;
  margin: auto;
  text-align: center;
`;

export const BorrowSection = styled.section`
  display: flex;
  align-items: center;
  height: 385px;
  margin-bottom: 80px !important;
  background: linear-gradient(97.71deg, #e24180 0%, #ef7277 49.24%, #693fea 100%);
  text-align: center;

  ${theme.mediaQuery.min.sm} {
    margin-bottom: 120px !important;
  }
`;

export const StatsSection = styled(Flex)`
  justify-content: space-between;

  ${theme.mediaQuery.max.sm} {
    flex-direction: column;
  }

  ${theme.mediaQuery.min.sm} {
    margin-bottom: 80px;
  }
`;

export const StatsCard = styled.article`
  display: flex;
  width: 100%;
  border-radius: 5px;

  & + & {
    margin-top: 15px;
  }

  ${theme.mediaQuery.min.sm} {
    width: calc(50% - 15px);

    & + & {
      margin: 0;
    }
  }
`;

export const StatsCardHeader = styled(Flex)`
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 70px;
  border-right: 1px solid rgba(255, 255, 255, 0.2);

  ${theme.mediaQuery.min.sm} {
    width: 90px;
  }
`;

export const StatsCardBody = styled.div`
  flex-grow: 1;
  padding: 20px;
  font-size: 14px;

  ${theme.mediaQuery.min.sm} {
    padding: 30px;
  }

  h4 {
    font-weight: 600;
    font-size: 22px;
  }
`;
