/** @jsxImportSource theme-ui */
import React from 'react';
import styled from 'styled-components';
import Modal from '@groot/components/VideoModal';

import videoImage from '../images/VideoImage.png';
import videoPlayIcon from '../images/VideoPlayIcon.png';

const VideoImageContainer = styled.div`
  position: relative;
  cursor: pointer;
`;

const VideoImage = styled.img`
  width: 100%;
  border-radius: 5px;
`;

const VideoPlayIcon = styled.img`
  position: absolute;
  top: 72%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70px;
  height: 70px;
`;

const HeroVideo = (props) => {
  const [showModal, setShowModal] = React.useState(false);

  return (
    <>
      <VideoImageContainer
        onClick={() => {
          setShowModal(true);
        }}
      >
        <VideoImage src={videoImage} />
        <VideoPlayIcon src={videoPlayIcon} />
      </VideoImageContainer>
      <Modal
        title="GrowthDEFI Intro"
        show={showModal}
        onHide={() => {
          setShowModal(false);
        }}
        videoId="UAO_32wBq34"
      />
    </>
  );
};

export default HeroVideo;
