/** @jsxImportSource theme-ui */
import React from 'react';
import styled from 'styled-components';
import Modal from '@groot/components/VideoModal';

import videoImage from '../images/VideoImage.jpg';

const VideoImageContainer = styled.div`
  cursor: pointer;

  img {
    width: 100%;
    border-radius: 5px;
  }
`;

const HeroVideo = (props) => {
  const [showModal, setShowModal] = React.useState(false);

  return (
    <>
      <VideoImageContainer
        onClick={() => {
          setShowModal(true);
        }}
      >
        <img src={videoImage} />
      </VideoImageContainer>
      <Modal
        title="Gro Intro"
        show={showModal}
        onHide={() => {
          setShowModal(false);
        }}
        videoId="97T6cxCQvwg"
      />
    </>
  );
};

export default HeroVideo;
