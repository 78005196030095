/** @jsxImportSource theme-ui */
import React, { useState, useEffect } from 'react';
import { Flex as MuiFlex } from 'theme-ui';
import styled from 'styled-components/macro';

const Image = styled.a`
  width: 30%;
  height: 100px;
  border: solid 1px gray;
  margin-bottom: 25px;

  img {
    width: 100%;
    height: 100px;
    padding: 20px;
  }
`;

const GalleryContainer = styled.div`
  width: 100%;
  padding: 20px;

  h2 {
    margin-bottom: 25px;
  }
`;

const Flex = styled(MuiFlex)`
  width: 100%;
  margin-top: 25px;
  justify-content: space-between;
`;

const Gallery = () => {
  return (
    <GalleryContainer>
      <h2>GrowthDeFi Gallery</h2>
      <div>
        <Flex sx={{ m: 'auto' }}>
          <Image href="./assets/gallery/gro_logo_black.svg" target="_blank">
            <img src="./assets/gallery/gro_logo_black.svg" alt="No-Image" />
          </Image>
          <Image href="./assets/gallery/gro_logo_white.svg" target="_blank">
            <img src="./assets/gallery/gro_logo_white.svg" alt="No-Image" />
          </Image>
          <Image href="./assets/gallery/gro_token.svg" target="_blank">
            <img src="./assets/gallery/gro_token.svg" alt="No-Image" />
          </Image>
        </Flex>
        <Flex>
          <Image href="./assets/gallery/growthdefi_black.svg" target="_blank">
            <img src="./assets/gallery/growthdefi_black.svg" alt="No-Image" />
          </Image>
          <Image href="./assets/gallery/growthdefi_white.svg" target="_blank">
            <img src="./assets/gallery/growthdefi_white.svg" alt="No-Image" />
          </Image>
          <Image href="./assets/gallery/mor_logo_black.svg" target="_blank">
            <img src="./assets/gallery/mor_logo_black.svg" alt="No-Image" />
          </Image>
        </Flex>
        <Flex>
          <Image href="./assets/gallery/mor_logo_white.svg" target="_blank">
            <img src="./assets/gallery/mor_logo_white.svg" alt="No-Image" />
          </Image>
          <Image href="./assets/gallery/mor_token.svg" target="_blank">
            <img src="./assets/gallery/mor_token.svg" alt="No-Image" />
          </Image>
          <Image href="./assets/gallery/cLQDR.svg" target="_blank">
            <img src="./assets/gallery/cLQDR.svg" alt="No-Image" />
          </Image>
        </Flex>
        <Flex>
          <Image href="./assets/gallery/growthdefi_logo.svg" target="_blank">
            <img src="./assets/gallery/growthdefi_logo.svg" alt="No-Image" />
          </Image>
        </Flex>
      </div>
    </GalleryContainer>
  );
};

export default Gallery;
