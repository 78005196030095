/** @jsxImportSource theme-ui */
import React from 'react';
import styled from 'styled-components';
import { jsx, Container as ThemeContainer } from 'theme-ui';
import { theme } from '@groot/shared/util';

const StyledContainer = styled(ThemeContainer)`
  padding-right: 15px;
  padding-left: 15px;

  ${theme.mediaQuery.min.sm} {
    padding-right: 30px;
    padding-left: 30px;
  }
`;

// eslint-disable-next-line react/prop-types
const Container = ({ children, ...props }) => {
  return <StyledContainer {...props}>{children}</StyledContainer>;
};

export default Container;
