import React, { useContext } from 'react';
import { Modal, Button } from 'react-bootstrap';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import FTMLogo from '../images/ftm.png';
import AVAXLogo from '../images/avax.png';
import BSCLogo from '../images/bsc.png';

const StyledModal = styled(Modal)`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  justify-content: center;
  align-items: center;
  display: flex !important;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  background-color: rgba(0, 0, 0, 0.5);

  .modal-dialog {
    position: relative;
    width: 100%;
    max-width: 500px;
    pointer-events: none;
  }

  .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: rgb(19, 19, 19);
    background-clip: padding-box;
    border: 1px solid gray;
    border-radius: 5px;
    outline: 0;
  }

  .modal-footer {
    display: flex;
    flex-wrap: wrap;
    flex-shrink: 0;
    align-items: center;
    justify-content: flex-end;
    padding: 0.75rem;
    border: none;
    border-bottom-right-radius: calc(0.3rem - 1px);
    border-bottom-left-radius: calc(0.3rem - 1px);
  }

  .close {
    background: none;
    border: none;
    font-size: 24px;
    line-height: 20px;
    color: #ffffff;
    cursor: pointer;
    padding: 10px;
    &:hover {
      border: solid 1px gray;
      border-radius: 8px;
    }
  }
`;

const StyledModalHeader = styled(Modal.Header)`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 30px;
  border: none;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
`;

const StyledModalTitle = styled(Modal.Title)`
  font-weight: 600;
  font-size: 24px;
  line-height: 1;
`;

const StyledModalBody = styled(Modal.Body)`
  position: relative;
  flex: 1 1 auto;
  padding: 0 30px 30px;
  font-size: 24px;
`;

const Image = styled.img`
  height: 32px;
  width: 32px;
  margin-right: 10px;
`;

const Option = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 5px;
  cursor: pointer;
  &:hover {
    background-color: gray;
  }
`;

export default function SelectChain(props) {
  const onClickChain = (e) => {
    const chain = e.target.id.split('-')[1];
    props.setShowSelectChain(false);
    props.setChain(chain);
  };
  return (
    <StyledModal
      show={props.showSelectChain}
      onHide={() => props.setShowSelectChain(false)}
      keyboard={false}
      className="info-modal"
      centered
    >
      <StyledModalHeader closeButton>
        <StyledModalTitle>Select Chain</StyledModalTitle>
      </StyledModalHeader>
      <StyledModalBody>
        <Option onClick={onClickChain} id="chain-AVAX">
          <Image src={AVAXLogo} alt="Avax-logo" /> AVAX
        </Option>
        <Option onClick={onClickChain} id="chain-BSC">
          <Image src={BSCLogo} alt="Bsc-logo" />
          BSC
        </Option>
        <Option onClick={onClickChain} id="chain-FTM">
          <Image src={FTMLogo} alt="Ftm-logo" />
          FTM
        </Option>
      </StyledModalBody>
    </StyledModal>
  );
}

SelectChain.propTypes = {
  showSelectChain: PropTypes.bool,
  setShowSelectChain: PropTypes.any,
  setChain: PropTypes.any,
};
