export default [
  { inputs: [], stateMutability: 'nonpayable', type: 'constructor' },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'owner',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'spender',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'value',
        type: 'uint256',
      },
    ],
    name: 'Approval',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: '_voter',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: '_oldCandidate',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: '_newCandidate',
        type: 'address',
      },
    ],
    name: 'ChangeCandidate',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: '_candidate',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: '_oldVotes',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: '_newVotes',
        type: 'uint256',
      },
    ],
    name: 'ChangeVotes',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'from', type: 'address' },
      { indexed: true, internalType: 'address', name: 'to', type: 'address' },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'value',
        type: 'uint256',
      },
    ],
    name: 'Transfer',
    type: 'event',
  },
  {
    inputs: [
      { internalType: 'address', name: 'owner', type: 'address' },
      { internalType: 'address', name: 'spender', type: 'address' },
    ],
    name: 'allowance',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'spender', type: 'address' },
      { internalType: 'uint256', name: 'amount', type: 'uint256' },
    ],
    name: 'approve',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
    name: 'balanceOf',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: '_netShares', type: 'uint256' },
      { internalType: 'uint256', name: '_totalReserve', type: 'uint256' },
      { internalType: 'uint256', name: '_totalSupply', type: 'uint256' },
      { internalType: 'uint256', name: '_depositFee', type: 'uint256' },
    ],
    name: 'calcDepositCostFromShares',
    outputs: [
      { internalType: 'uint256', name: '_cost', type: 'uint256' },
      { internalType: 'uint256', name: '_feeShares', type: 'uint256' },
    ],
    stateMutability: 'pure',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: '_cost', type: 'uint256' },
      { internalType: 'uint256', name: '_totalReserve', type: 'uint256' },
      { internalType: 'uint256', name: '_totalSupply', type: 'uint256' },
      { internalType: 'uint256', name: '_depositFee', type: 'uint256' },
    ],
    name: 'calcDepositSharesFromCost',
    outputs: [
      { internalType: 'uint256', name: '_netShares', type: 'uint256' },
      { internalType: 'uint256', name: '_feeShares', type: 'uint256' },
    ],
    stateMutability: 'pure',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: '_grossShares', type: 'uint256' },
      { internalType: 'uint256', name: '_totalReserve', type: 'uint256' },
      { internalType: 'uint256', name: '_totalSupply', type: 'uint256' },
      { internalType: 'uint256', name: '_withdrawalFee', type: 'uint256' },
    ],
    name: 'calcWithdrawalCostFromShares',
    outputs: [
      { internalType: 'uint256', name: '_cost', type: 'uint256' },
      { internalType: 'uint256', name: '_feeShares', type: 'uint256' },
    ],
    stateMutability: 'pure',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: '_cost', type: 'uint256' },
      { internalType: 'uint256', name: '_totalReserve', type: 'uint256' },
      { internalType: 'uint256', name: '_totalSupply', type: 'uint256' },
      { internalType: 'uint256', name: '_withdrawalFee', type: 'uint256' },
    ],
    name: 'calcWithdrawalSharesFromCost',
    outputs: [
      { internalType: 'uint256', name: '_grossShares', type: 'uint256' },
      { internalType: 'uint256', name: '_feeShares', type: 'uint256' },
    ],
    stateMutability: 'pure',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: '', type: 'address' }],
    name: 'candidate',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'decimals',
    outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'spender', type: 'address' },
      { internalType: 'uint256', name: 'subtractedValue', type: 'uint256' },
    ],
    name: 'decreaseAllowance',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: '_cost', type: 'uint256' }],
    name: 'deposit',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'depositFee',
    outputs: [{ internalType: 'uint256', name: '_depositFee', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'spender', type: 'address' },
      { internalType: 'uint256', name: 'addedValue', type: 'uint256' },
    ],
    name: 'increaseAllowance',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'name',
    outputs: [{ internalType: 'string', name: '', type: 'string' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'reserveToken',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: '_newCandidate', type: 'address' }],
    name: 'setCandidate',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'symbol',
    outputs: [{ internalType: 'string', name: '', type: 'string' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'totalReserve',
    outputs: [{ internalType: 'uint256', name: '_totalReserve', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'totalSupply',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'recipient', type: 'address' },
      { internalType: 'uint256', name: 'amount', type: 'uint256' },
    ],
    name: 'transfer',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'sender', type: 'address' },
      { internalType: 'address', name: 'recipient', type: 'address' },
      { internalType: 'uint256', name: 'amount', type: 'uint256' },
    ],
    name: 'transferFrom',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: '_candidate', type: 'address' }],
    name: 'votes',
    outputs: [{ internalType: 'uint256', name: '_votes', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: '_grossShares', type: 'uint256' }],
    name: 'withdraw',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'withdrawalFee',
    outputs: [{ internalType: 'uint256', name: '_withdrawalFee', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
];
