/* eslint-disable react/no-unknown-property */
import React, { useContext } from 'react';
import styled from 'styled-components';
import Button from 'react-bootstrap/Button';

import GrootUiYieldContext from '@groot/shared/context/groot-ui-yield-context';
import useBalance from './hooks/useBalance';
import useAPR from './hooks/useAPR';
import useUserInfo from './hooks/useUserInfo';
import useGRootHarvest2 from './hooks/useGRootHarvest2';
import useGRootHarvest from './hooks/useGRootHarvest';
import { numberWithCommas, toFixedTrunc } from '@groot/shared/util';
import { StyledPoolCard } from './styles';

import vdcCompound from '@groot/shared/assets/images/vdc-compound.png';
import vdcVolatile from '@groot/shared/assets/images/vdc-volatile.png';
// import GlobalStyle from '@groot/shared/assets/yield-styles';
// import '@groot/shared/assets/stylesheets/custom.css';

const compound = [];
const volatile = [];

const Title = styled.h1`
  font-family: Roboto;
  font-weight: bold;
  word-wrap: break-word;
  font-size: 40px;
  @media (min-width: 768px) {
    font-size: 82px;
  }
`;

export default function GrootUiYield() {
  const { lastUpdatedTime } = useContext(GrootUiYieldContext);
  const { bnbBalance, gRootBalance, groBalance } = useBalance(lastUpdatedTime);
  const { gROOTAPR, groAPR } = useAPR(lastUpdatedTime);
  const {
    depositAmount,
    withdrawAmount,
    depositFee,
    withdrawFee,
    setDepositAmount,
    setWithdrawAmount,
    onGRootDeposit,
    onGRootWithdraw,
    depositAmountGRO,
    withdrawAmountGRO,
    depositFeeGRO,
    withdrawFeeGRO,
    setDepositAmountGRO,
    setWithdrawAmountGRO,
    onGRODeposit,
    onGROWithdraw,
  } = useGRootHarvest();
  const { onGRootClaimBNB, onGRootCompoundBNB, onGROClaimBNB, onGROCompoundBNB } =
    useGRootHarvest2();
  const {
    userReserveGROOT,
    userRewardGROOT,
    userUSDReserveGROOT,
    userRewardGRO,
    userReserveGRO,
    userUSDReserveGRO,
    userGrootRewardInUSD,
    userGRORewardInUSD,
    totalReserveGROOT,
    totalUSDReserveGROOT,
    totalReserveGRO,
    totalUSDReserveGRO,
  } = useUserInfo(lastUpdatedTime);

  return (
    <div className="harvest-container">
      <div className="Uniswap container" style={{ paddingTop: '0%' }}>
        <div className="row text-center margin-auto">
          <div className="col-md-12">
            <Title className="componenet-title font-Roboto">
              <span>GRO</span>
              <span>/</span>
              <span>gROOT</span> STAKING
            </Title>
          </div>
        </div>
        <div className="row text-center margin-auto">
          <div className="col-md-12">
            <p className="text-white">You can buy both GRO and gROOT on BSC below</p>
          </div>
          <div className="col-md-12">
            <a
              href="https://exchange.pancakeswap.finance/#/swap?inputCurrency=BNB&outputCurrency=0x336ed56d8615271b38ecee6f4786b55d0ee91b96"
              target="_blank"
              rel="noreferrer noopener"
              className="lp-btn btn-leaf light-blue-button btn btn-primary head-buy-button"
            >
              Buy GRO
            </a>

            <a
              href="https://exchange.pancakeswap.finance/#/swap?inputCurrency=BNB&outputCurrency=0x8b571fe684133aca1e926beb86cb545e549c832d"
              target="_blank"
              rel="noreferrer noopener"
              className="lp-btn btn-leaf light-blue-button btn btn-primary head-buy-button"
            >
              Buy gROOT
            </a>
          </div>
        </div>
        <div className="row text-center margin-auto" style={{ paddingTop: '25px' }}>
          <div className="col-md-6 col-lg-4 margin-auto">
            <p
              className="text-white margin-bottom-zero"
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              BNB Balance : <span id="iostBalance">{toFixedTrunc(bnbBalance)}</span>
            </p>
            <p
              className="text-white margin-bottom-zero"
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              gROOT Balance : <span id="pmineBalance">{toFixedTrunc(gRootBalance)}</span>
            </p>
            <p
              className="text-white margin-bottom-zero"
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              GRO Balance : <span id="perBalance">{toFixedTrunc(groBalance)}</span>
            </p>
          </div>
        </div>
        <div className="row margin-auto">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
            <div className="row text-center">
              <div className="col-md-12">
                <h2 className="loans-page_orange-color" style={{ marginBottom: '0px' }}>
                  GRO Yield
                </h2>
                {/* <p className="loans-page_orange-color">1% PMINE Daily drip pool</p> */}
              </div>
            </div>

            <div className="row text-center vdc-alert" id="statusVDC1Msg"></div>

            <div className="uniswap-container" style={{ background: 'transparent' }}>
              <div className="swap">
                <StyledPoolCard style={{ height: 'auto' }}>
                  <form action="" autoComplete="off">
                    <div className="containers">
                      <div className="row text-center">
                        <div className="col-md-4 col-sm-12">
                          <input
                            type="number"
                            placeholder="0.0"
                            id="vdc1-deposit-amount"
                            value={depositAmountGRO}
                            onChange={(e) => setDepositAmountGRO(e.currentTarget.value)}
                          />
                          <br />
                          {/* <div align-center="center" style={{marginTop: '10px'}}>
                                                <a href="javascript:void(0);" className="rocket-button gold-rocket-button"
                                                  id="vdc1-deposit-btn">
                                                    <div className="default">Deposit</div>
                                                </a>
                                            </div> */}
                          <Button
                            className="lp-btn light-blue-outline-button"
                            style={{ marginTop: '10px' }}
                            variant="outline-info light-blue-outline-button"
                            onClick={onGRODeposit}
                          >
                            Deposit
                          </Button>
                          <br />
                          BNB Fee: {toFixedTrunc(depositFeeGRO)}
                        </div>

                        <div className="col-md-4 col-sm-12">
                          <img
                            src={vdcCompound}
                            alt="profile"
                            style={{ height: '70px', marginTop: '5px' }}
                          />
                        </div>

                        <div className="col-md-4 col-sm-12">
                          <input
                            type="number"
                            placeholder="0.0"
                            id="vdc1-withdraw-amount"
                            value={withdrawAmountGRO}
                            onChange={(e) => setWithdrawAmountGRO(e.currentTarget.value)}
                          />
                          <br />
                          {/* <div align-center="center" style={{marginTop: '10px'}}>
                                                <a href="javascript:void(0);" className="rocket-button gold-rocket-button"
                                                  id="vdc1-withdraw-btn">
                                                    <div className="default">Withdraw</div>
                                                </a>
                                            </div> */}
                          <Button
                            className="lp-btn btn-leaf light-blue-button"
                            style={{ marginTop: '10px' }}
                            onClick={onGROWithdraw}
                          >
                            Withdraw{' '}
                          </Button>
                          <br />
                          BNB Fee: {toFixedTrunc(withdrawFeeGRO)}
                        </div>
                      </div>
                    </div>

                    <div className="row text-center">
                      <div className="col-md-12 col-md-offset-3">
                        <b className="loans-page_orange-color orange-to-white">YOUR HOLDINGS</b>
                        <br />
                      </div>
                    </div>

                    <div className="row text-center">
                      <div className="col-md-12 col-md-offset-3" id="pmine-holdings-1">
                        <p className="loans-page_orange-color orange-to-white">
                          <span id="vdc1-holding-pmine">{toFixedTrunc(userReserveGRO)}</span> GRO ($
                          {userUSDReserveGRO.toFixed(2)})
                        </p>
                      </div>
                    </div>

                    <div align-center="center">
                      {/* <a href="javascript:void(0);" className="rocket-button gold-rocket-button"
                                      id="vdc1-claim-btn">
                                        <div className="default">CLAIM REWARDS</div>
                                    </a> */}
                      <Button
                        className="lp-btn"
                        variant="outline-info light-blue-outline-button"
                        onClick={onGROClaimBNB}
                      >
                        Claim Rewards{' '}
                      </Button>

                      <div className="row text-center">
                        <div className="col-md-12 col-md-offset-3">
                          <b className="loans-page_orange-color orange-to-white">YOUR REWARDS</b>
                          <br />
                        </div>
                      </div>

                      <div className="row text-center">
                        <div className="col-md-12">
                          <p
                            className="loans-page_orange-color orange-to-white"
                            id="vdc1-pmine-reward"
                          >
                            {toFixedTrunc(userRewardGRO)} BNB ($
                            {userGRORewardInUSD.toFixed(2)})
                          </p>
                        </div>
                        {/* <div className="col-md-4">
                                            <p className="loans-page_orange-color orange-to-white" style={{marginBottom: '0px'}}>IOST</p>
                                            <p className="loans-page_orange-color orange-to-white" id="vdc1-iost-reward">0.00000000</p>
                                        </div>
                                        <div className="col-md-4">
                                            <p className="loans-page_orange-color orange-to-white" style={{marginBottom: '0px'}}>PER</p>
                                            <p className="loans-page_orange-color orange-to-white" id="vdc1-per-reward">0.00000000</p>
                                        </div> */}
                      </div>
                      <div className="text-center">
                        <Button
                          className="lp-btn btn-leaf light-blue-button"
                          style={{ marginBottom: '5px' }}
                          onClick={onGROCompoundBNB}
                        >
                          Compound All Rewards
                        </Button>
                      </div>
                      <div className="row text-center">
                        <div className="col-md-12 col-md-offset-3">
                          <b className="loans-page_orange-color orange-to-white">
                            APY for Compounding : {numberWithCommas(groAPR.toFixed(2))}%
                          </b>
                          <br />
                        </div>
                      </div>
                    </div>
                  </form>
                </StyledPoolCard>

                <div className="row text-center mt-2">
                  <div className="col-md-12 col-md-offset-3">
                    <b className="loans-page_orange-color orange-to-white">
                      TOTAL VALUE LOCKED IN CONTRACT
                    </b>
                    <br />
                    <p className="text-white">
                      {toFixedTrunc(totalReserveGRO)} GRO ($
                      {numberWithCommas(totalUSDReserveGRO.toFixed(2))})
                    </p>
                  </div>
                </div>

                <a
                  href="https://bscscan.com/token/0xDA2AE62e2B71ad3000BB75acdA2F8f68DC88aCE4#balances"
                  target="_blank"
                  className="richlist-link"
                  rel="noreferrer"
                >
                  TOP HOLDERS LIST
                </a>
              </div>
            </div>
          </div>

          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
            <div className="row text-center">
              <div className="col-md-12">
                <h2 className="loans-page_orange-color" style={{ marginBottom: '0px' }}>
                  gROOT Harvest
                </h2>
                {/* <p className="loans-page_orange-color">5% PMINE Daily drip pool</p> */}
              </div>
            </div>

            <div className="row text-center vdc-alert" id="statusVDC2Msg"></div>

            <div className="uniswap-container" style={{ background: 'transparent' }}>
              <div className="swap">
                <StyledPoolCard style={{ height: 'auto' }}>
                  <form action="" autoComplete="off">
                    <div className="containers">
                      <div className="row text-center">
                        <div className="col-md-4 col-sm-12">
                          <input
                            type="number"
                            placeholder="0.0"
                            value={depositAmount}
                            onChange={(e) => setDepositAmount(e.currentTarget.value)}
                          />
                          <br />
                          {/* <div align-center="center" style={{marginTop: '10px'}}>
                                                <a href="javascript:void(0);" className="rocket-button gold-rocket-button"
                                                  id="vdc2-deposit-btn">
                                                      
                                                    <div className="default">Deposit</div>
                                                </a>
                                            </div> */}
                          <Button
                            className="lp-btn light-blue-outline-button"
                            style={{ marginTop: '10px' }}
                            variant="outline-info"
                            onClick={onGRootDeposit}
                          >
                            Deposit
                          </Button>
                          <br />
                          BNB Fee: {toFixedTrunc(depositFee)}
                        </div>

                        <div className="col-md-4 col-sm-12">
                          <img
                            src={vdcVolatile}
                            alt="profile"
                            style={{ height: '70px', marginTop: '5px' }}
                          />
                        </div>

                        <div className="col-md-4 col-sm-12">
                          <input
                            type="number"
                            placeholder="0.0"
                            value={withdrawAmount}
                            onChange={(e) => setWithdrawAmount(e.currentTarget.value)}
                          />
                          <br />
                          {/* <div align-center="center" style={{marginTop: '10px'}}>
                                                <a href="javascript:void(0);" className="rocket-button gold-rocket-button"
                                                  id="vdc2-withdraw-btn">
                                                    <div className="default">Withdraw</div>
                                                </a>
                                            </div> */}
                          <Button
                            className="lp-btn btn-leaf light-blue-button"
                            style={{ marginTop: '10px' }}
                            onClick={onGRootWithdraw}
                          >
                            Withdraw
                          </Button>
                          <br />
                          BNB Fee: {toFixedTrunc(withdrawFee)}
                        </div>
                      </div>
                    </div>

                    <div className="row text-center">
                      <div className="col-md-12 col-md-offset-3">
                        <b className="loans-page_orange-color orange-to-white">YOUR HOLDINGS</b>
                        <br />
                      </div>
                    </div>

                    <div className="row text-center">
                      <div className="col-md-12 col-md-offset-3" id="pmine-holdings-1">
                        <p className="loans-page_orange-color orange-to-white">
                          <span id="vdc1-holding-pmine">{toFixedTrunc(userReserveGROOT)}</span>{' '}
                          gROOT (${userUSDReserveGROOT.toFixed(2)})
                        </p>
                      </div>
                    </div>

                    <div align-center="center">
                      {/* <a href="javascript:void(0);" className="rocket-button gold-rocket-button"
                                      id="vdc2-claim-btn">
                                        <div className="default">CLAIM REWARDS</div>
                                    </a> */}
                      <Button
                        className="lp-btn"
                        variant="outline-info light-blue-outline-button"
                        onClick={onGRootClaimBNB}
                      >
                        Claim Rewards{' '}
                      </Button>

                      <div className="row text-center">
                        <div className="col-md-12 col-md-offset-3">
                          <b className="loans-page_orange-color orange-to-white">YOUR REWARDS</b>
                          <br />
                        </div>
                      </div>

                      <div className="row text-center">
                        <div className="col-md-12">
                          <p
                            className="loans-page_orange-color orange-to-white"
                            id="vdc1-pmine-reward"
                          >
                            {toFixedTrunc(userRewardGROOT)} BNB ($
                            {userGrootRewardInUSD.toFixed(2)})
                          </p>
                        </div>
                        {/* <div className="col-md-4">
                                            <p className="loans-page_orange-color orange-to-white" style={{marginBottom: '0px'}}>IOST</p>
                                            <p className="loans-page_orange-color orange-to-white" id="vdc1-iost-reward">0.00000000</p>
                                        </div>
                                        <div className="col-md-4">
                                            <p className="loans-page_orange-color orange-to-white" style={{marginBottom: '0px'}}>PER</p>
                                            <p className="loans-page_orange-color orange-to-white" id="vdc1-per-reward">0.00000000</p>
                                        </div> */}
                      </div>
                      <div className="text-center">
                        <Button
                          className="lp-btn btn-leaf light-blue-button"
                          style={{ marginBottom: '5px' }}
                          onClick={onGRootCompoundBNB}
                        >
                          Compound All Rewards
                        </Button>
                      </div>
                      <div className="row text-center">
                        <div className="col-md-12 col-md-offset-3">
                          <b className="loans-page_orange-color orange-to-white">
                            APY for Compounding : {numberWithCommas(gROOTAPR.toFixed(2))}%
                          </b>
                          <br />
                        </div>
                      </div>
                    </div>
                  </form>
                </StyledPoolCard>

                <div className="row text-center mt-2">
                  <div className="col-md-12 col-md-offset-3">
                    <b className="loans-page_orange-color orange-to-white">
                      TOTAL VALUE LOCKED IN CONTRACT
                    </b>
                    <br />
                    <p className="text-white">
                      {toFixedTrunc(totalReserveGROOT)} gROOT ($
                      {numberWithCommas(totalUSDReserveGROOT.toFixed(2))})
                    </p>
                  </div>
                </div>

                <a
                  href="https://bscscan.com/token/0x65d2Ca0A5a34234c36e7b7E752fA67AC2CCBB203#balances"
                  target="_blank"
                  className="richlist-link"
                  rel="noreferrer"
                >
                  TOP HOLDERS LIST
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="row margin-auto">
          <div className="col-xs-12 col-sm-12 col-md-4 text-center"></div>
          <div className="col-xs-12 col-sm-12 col-md-4 text-center">
            <p className="loans-page_orange-color">
              <span style={{ fontSize: '1.1rem', fontWeight: 'bold' }}>
                GRO/gROOT STAKING fees and information
              </span>
              <br />
              GRO/gROOT staking fees are 11% in and 11% out, they are distributed in the following
              way:
              <br />
              3% market buys gROOT
              <br />
              1% Dev fee
              <br />
              7% Treasury
              <br />
              Receives BNB reward every block, which can be compounded to increase your stake of
              GRO/gROOT.
            </p>
          </div>
        </div>
      </div>

      {/* <GlobalStyle /> */}
    </div>
  );
}
