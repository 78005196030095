import styled from 'styled-components';
import { theme } from '@groot/shared/util';

export const CounterContainer = styled.div`
  margin: 0 0 60px;
  padding: 60px 30px;
  text-align: center;
  width: 100%;
  border-radius: 15px;

  // img {
  //   height: 28px;
  // }
`;

export const CounterItems = styled.div`
  display: flex;
  align-item: center;
  justify-content: center;
`;

export const CounterItem = styled.div`
  margin: 12px 0;

  ${theme.mediaQuery.max.sm} {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0 5px;
  }

  ${theme.mediaQuery.min.sm} {
    margin: 0 15px;
  }

  svg path:nth-child(2) {
    stroke-width: 2px;
  }

  svg path:nth-child(3) {
    stroke-width: 5px;
  }
`;

export const CounterItemText = styled.div`
  margin-top: 15px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
  color: rgb(110, 65, 232);
`;

export const CounterItemValue = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 1;
  color: rgb(107, 64, 232);

  ${theme.mediaQuery.min.sm} {
    font-size: 42px;
  }
`;

export const WheatLogo = styled.img`
  position: relative;
  top: 2px;
  height: 48px !important;
  margin: 0 4px;
`;

export const FantomLogo = styled.img`
  position: relative;
  top: 9px;
  height: 48px !important;
  margin: 0 4px;
`;

export const MorLogo = styled.img`
  position: relative;
  top: 2px;
  height: 48px !important;
  margin: 0 4px;
`;
