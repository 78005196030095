import styled from 'styled-components';
import { theme } from '@groot/shared/util';
import patternImage from './images/PatternImage.png';

export const Page = styled.main`
  background-image: url(${patternImage});
  background-repeat: no-repeat;
  background-position: center 100px;

  ${theme.mediaQuery.min.sm} {
    background-size: 100% auto;
  }
`;

export const Hero = styled.section`
  padding: 60px 0 80px;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: bottom center;

  ${theme.mediaQuery.max.sm} {
    text-align: center;
  }

  ${theme.mediaQuery.min.sm} {
    margin-bottom: 62px;
    padding: 62px 0;
  }
`;

export const HeroContent = styled.article`
  width: 100%;

  ${theme.mediaQuery.min.sm} {
    width: 50%;
    max-width: 570px;
    margin-top: 42px;
    padding-right: 15px;
  }

  p {
    color: #9c9c9c;

    ${theme.mediaQuery.max.sm} {
      margin-bottom: 20px;
    }
  }
`;

export const HeroObject = styled.article`
  width: 100%;

  ${theme.mediaQuery.min.sm} {
    width: 50%;
    max-width: 540px;
    padding-left: 15px;
  }

  img {
    display: block;
    width: 100%;
  }
`;

export const CardsList = styled.section`
  display: flex;
  flex-flow: row wrap;
  margin: -8px 0;
  padding: 0 0 80px;

  ${theme.mediaQuery.min.sm} {
    margin: -15px;
    padding: 0 0 120px;
  }
`;

export const Card = styled.article`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 340px;
  margin: 8px 0;
  padding: 30px 30px 24px;
  background: #1c1c1c;
  border-radius: 5px;

  ${theme.mediaQuery.min.sm} {
    width: calc((100% / 3) - 30px);
    margin: 15px;
    text-align: center;

    span {
      font-weight: 400;
    }
  }
`;

export const CardTitle = styled.h4`
  margin-bottom: 24px;
  text-align: center;
  font-weight: 600;
  font-size: 22px;
`;

export const CardFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: auto;
  margin-bottom: 0;
  padding-top: 24px;
  border-top: 1px solid #313131;
`;

export const MorLogo = styled.img`
  width: 80px;
  height: 80px;
  margin: 20px auto 30px;
`;

export const WheatLogo = styled.img`
  width: 74px;
  height: 80px;
  margin: 20px auto 30px;
`;

export const CertikLogo = styled.img`
  width: 116px;
  height: 28px;
  margin: 0 auto;
`;

export const DefiYeldLogo = styled.img`
  width: 116px;
  height: 28px;
  margin: 0 auto;
`;

export const IliDiligenceLogo = styled.img`
  width: 116px;
  height: 30px;
  margin: 0 auto;
`;
