import { MasterChefABI } from './MasterChefABI';
import { StkgRootToBnbABI } from './stkgRootToBNBABI';
import GRootBnbHarvestABI from './GRootBnbHarvestABI';
import GRootContractABI from './GRootContractABI';
import GROContractABI from './GROContractABI';
import GHarvestTokenHelperABI from './GHarvestTokenHelperABI';
import YieldContractABI from './YieldContractABI';

// type NetworkName = string;
// type TokenName = string;
// type TokenPairName = string;
// type AnyABI = any[];

// interface TokenConfig {
//   contract: string; // 0x12345
//   abi: AnyABI;
//   decimals: number;
// }
// interface SmartContractConfig {
//   contract: string;
//   abi: AnyABI;
//   byteCode?: string;
// }
// interface TokenPairConfig {
//   decimals: number;
//   ether_bridge?: string;
//   ether_bridge_abi?: string; // 0x12345
//   liquidity_pool_abi: AnyABI;
//   liquidity_pool_contract: string; // 0x12345
//   staking_abi: AnyABI;
//   staking_contract: string;
//   pid: number;
// }

// interface NetworkConfig {
//   networkRouterAddress: string; // 0x12345
//   smartContracts: Record<TokenName, SmartContractConfig>;
//   tokens: Record<TokenName, TokenConfig>;
//   tokenPairs: Record<TokenPairName, TokenPairConfig>
// }

export const getTokenPairFromTokenNames = (tokenName1 = '', tokenName2 = '') => {
  const token1IsFirst = tokenName1.localeCompare(tokenName2) <= 0;
  return token1IsFirst ? `${tokenName1}_${tokenName2}` : `${tokenName2}_${tokenName1}`;
};

export const getTokenPairConfigData = (network = 'global', tokenName1 = '', tokenName2 = '') => {
  const pair = getTokenPairFromTokenNames(tokenName1, tokenName2);
  return GrootContractData[network].tokenPairs[pair];
};

// type ContractData = Record<NetworkName, NetworkConfig>;

const GrootContractData = {
  global: {
    networkRouterAddress: null,
    smartContracts: {
      MasterChefFarming: {
        contract: '0x1af3Baf88584bda73793913974c6ccb3AfAED54f',
        abi: MasterChefABI,
      },
      GROOT_BNB_HARVEST: {
        contract: '0x65d2Ca0A5a34234c36e7b7E752fA67AC2CCBB203',
        abi: GRootBnbHarvestABI,
      },
      GRO_BNB_HARVEST: {
        contract: '0xDA2AE62e2B71ad3000BB75acdA2F8f68DC88aCE4',
        abi: YieldContractABI,
      },
      GHARVEST_TOKEN_HELPER: {
        contract: '0x91F1430833879272643658f8eD07d60257dDf321',
        abi: GHarvestTokenHelperABI,
      },
    },
    tokens: {
      gROOT: {
        contract: '0x8b571fe684133aca1e926beb86cb545e549c832d',
        abi: GRootContractABI,
        decimals: 1e18,
      },
      GRO: {
        contract: '0x336ed56d8615271b38ecee6f4786b55d0ee91b96',
        abi: GROContractABI,
        decimals: 1e18,
      },
    },
    tokenPairs: {
      BNB_GROOT: {
        decimals: 1e18,
        liquidity_pool_abi: null,
        liquidity_pool_contract: '0x7Fb2C6B9377480FcFB3afB987fD5be6F6139d8c4',
        staking_abi: StkgRootToBnbABI,
        staking_contract: '0x168306da65229417175EB942D15345585429352f',
        pid: 1,
      },
      BNB_GRO: {
        decimals: 1e18,
        liquidity_pool_abi: null,
        liquidity_pool_contract: '0x5eEb5A7d5229687698825d30e8302A56c5404d4b',
        staking_abi: StkgRootToBnbABI,
        staking_contract: '',
        pid: 1,
      },
      BNB_BUSD: {
        decimals: 1e18,
        liquidity_pool_abi: null,
        liquidity_pool_contract: '0x1b96b92314c44b159149f7e0303511fb2fc4774f',
        staking_abi: null,
        staking_contract: null,
        pid: null,
      },
    },
  },
};

export default GrootContractData;
