/* eslint-disable react/no-unknown-property */
/** @jsxImportSource theme-ui */
import React from 'react';
import styled from 'styled-components';
import { jsx, Flex } from 'theme-ui';
import Container from '@groot/components/Container';
import LinksList from './LinksList';
import Logo from '../NewHeader/Logo';
import { theme } from '@groot/shared/util';

import { ReactComponent as TelegramIcon } from './icons/Telegram.svg';
import { ReactComponent as TwitterIcon } from './icons/Twitter.svg';
import { ReactComponent as MediumIcon } from './icons/Medium.svg';
import { ReactComponent as YoutubeIcon } from './icons/Youtube.svg';
import { ReactComponent as DiscordIcon } from './icons/Discord.svg';
import GeckoIcon from 'assets/img/geckoterminal_dark.png';

const Footer = styled.footer`
  padding-top: 50px;
  border-top: 1px solid rgb(49, 49, 49);

  ${theme.mediaQuery.min.sm} {
    padding-top: 100px;
  }
`;

const Content = styled(Flex)`
  justify-content: space-between;

  ${theme.mediaQuery.max.sm} {
    flex-direction: column;
  }
`;

const Column = styled.article`
  ${theme.mediaQuery.max.sm} {
    & + & {
      margin-top: 40px;
    }
  }

  ${theme.mediaQuery.min.sm} {
    &:first-child {
      padding-right: 30px;
    }

    & + & {
      padding-left: 15px;
    }
  }

  ${theme.mediaQuery.min.md} {
    &:first-child {
      padding-right: 98px;
    }
  }
`;

const Copyright = styled.div`
  margin-top: 40px;
  padding: 40px 0;
  border-top: 1px solid rgb(49, 49, 49);
  text-align: center;

  ${theme.mediaQuery.min.sm} {
    margin-top: 50px;
  }
`;

const GeckoImage = styled.img`
  display: block;
  height: 20px;
  margin-top: 30px;

  ${theme.mediaQuery.min.sm} {
    height: 30px;
  }
`;

const NewFooter = (props) => {
  return (
    <Footer>
      <Container>
        <Content>
          <Column>
            <Logo />
            <Flex sx={{ alignItems: 'center', marginTop: 30 }}>
              <a
                sx={{ display: 'flex', aligItems: 'center' }}
                href="https://t.me/GBTCpWBTCxxHMINE"
                target="_blank"
                rel="noreferrer noopener"
              >
                <TelegramIcon />
              </a>
              <a
                sx={{ display: 'flex', aligItems: 'center', marginLeft: 20 }}
                href="https://twitter.com/PerpetualDice3"
                target="_blank"
                rel="noreferrer noopener"
              >
                <TwitterIcon />
              </a>
              {/*<a
                sx={{ display: 'flex', aligItems: 'center', marginLeft: 20 }}
                href="https://growthdefi.medium.com/"
                target="_blank"
                rel="noreferrer noopener"
              >
                <MediumIcon />
  </a> */}
              <a
                sx={{ display: 'flex', aligItems: 'center', marginLeft: 20 }}
                href="https://www.youtube.com/channel/UCvGWDPZvbaaflScIRSALrFg/videos"
                target="_blank"
                rel="noreferrer noopener"
              >
                <YoutubeIcon />
              </a>
              <a
                sx={{ display: 'flex', aligItems: 'center', marginLeft: 20 }}
                href="https://growthdefi.medium.com"
                target="_blank"
                rel="noreferrer noopener"
              >
                <MediumIcon />
              </a>
              {/* <a
                sx={{ display: 'flex', aligItems: 'center', marginLeft: 20 }}
                href="https://discord.com/invite/qMGXvSjhNr"
                target="_blank"
                rel="noreferrer noopener"
              >
                <DiscordIcon />
              </a> */}
            </Flex>
            <a
              sx={{ display: 'flex', aligItems: 'center' }}
              href="https://www.geckoterminal.com/bsc/pools/0xdc612572219e50f5759a441bc9e6f14c92116757"
              target="_blank"
              rel="noreferrer noopener"
            >
              <GeckoImage src={GeckoIcon} alt="Gecko" />
            </a>
          </Column>
          <Column>
            <LinksList
              heading="Quick Links"
              items={[
                {
                  name: 'Home',
                  to: '/',
                },

                {
                  name: 'Audits',
                  to: '/audits',
                },
                // {
                //   name: 'Contract',
                //   to: '/contract',
                // },
              ]}
            />
          </Column>

          {/* <Column>
            <LinksList
              heading="GRO"
              items={[
                { name: 'GRO', to: '/gro' },
                {
                  name: 'Buy GRO (BSC)',
                  href: 'https://app.apeswap.finance/swap?inputCurrency=0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c&outputCurrency=0x4AdAE3Ad22c4e8fb56D4Ae5C7Eb3abC0dd2d3379',
                },
                {
                  name: 'GRO Staking (BSC)',
                  href: 'https://perps.growthdefi.com/xgro/staking',
                },
              ]}
            />
          </Column> */}
        </Content>
        <Copyright>
          <p
            sx={{ fontWeight: 400, fontSize: '15px', lineHeight: 1.5, color: 'rgb(156, 156, 156)' }}
          >
            © {new Date(Date.now()).getFullYear()} Growth DeFi. All rights reserved.
          </p>
        </Copyright>
      </Container>
    </Footer>
  );
};

export default NewFooter;
