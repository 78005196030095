import React, { useState } from 'react';
import GrootUiYieldContext from '@groot/shared/context/groot-ui-yield-context';
import GrowthdefiMainUiHarvest from './GrowthdefiMainUiHarvest';

export function GrowthdefiMainUiHarvestApp() {
  const [lastUpdatedTime, setLastUpdatedTime] = useState(Date.now());

  return (
    <GrootUiYieldContext.Provider value={{ lastUpdatedTime, setLastUpdatedTime }}>
      <GrowthdefiMainUiHarvest />
    </GrootUiYieldContext.Provider>
  );
}

export default GrowthdefiMainUiHarvestApp;
