import React from 'react';
import Container from '@groot/components/Container';
import { Button, Flex } from 'theme-ui';
import { theme } from '@groot/shared/util';
import groCoinImage from './images/groCoinImage.png';

import {
  Hero,
  HeroContent,
  HeroObject,
  AssetDocumentationSection,
  AssetDocumentationContent,
} from './gro.styles';

const GroETH = () => {
  return (
    <>
      <Hero>
        <Container>
          <Flex
            sx={{ alignItems: 'center', justifyContent: 'space-between', flexFlow: 'row wrap' }}
          >
            <HeroContent>
              <h1 sx={{ ...theme.styles.h1, mb: 20 }}>GRO Token on Ethereum</h1>
              <br />
              <p style={{ textAlign: 'left' }}>
                GRO is the governance token of the GROWTH DeFi ecosystem and also a deflationary
                token with mechanisms to grow in the long run. Under 200K circulating and decreasing
                fast.. GRO will be required to mint BONZ(AI) which will be launching first week of
                December!!
              </p>
              <br />
              <p style={{ textAlign: 'left' }}>
                <b>GRO Token:</b> 0x09e64c2b61a5f1690ee6fbed9baf5d6990f8dfd0 <br />
                <br />
                <b>DexTools:</b>{' '}
                <a
                  href="https://www.dextools.io/app/en/ether/pair-explorer/0x208bd5dc470eba21571ddb439801a614ed346376?t=1731350841787"
                  style={{ color: '#06ebc8' }}
                >
                  https://www.dextools.io/app/en/ether/pair-explorer/0x208bd5dc470eba21571ddb439801a614ed346376?t=1731350841787
                </a>{' '}
                <br />
                <br />
                <b>Email:</b> Admin@growthdefi.com
              </p>
            </HeroContent>
            <HeroObject>
              <img src={groCoinImage} alt="" style={{ width: '80%' }} />
            </HeroObject>
          </Flex>
        </Container>
      </Hero>

      <AssetDocumentationSection as="section">
        <Container>
          <AssetDocumentationContent>
            <h3 sx={{ ...theme.styles.h3, mb: 40 }}>
              Explore the Documentation to Learn How GRO Works Within the Growth DeFi Ecosystem
            </h3>
            <a href="/doc/Litepaper.pdf" target="_blank" rel="noreferrer">
              <Button variant="primary" sx={{ height: 50, pr: 30, pl: 30, fontSize: 16 }}>
                Asset Documentation
              </Button>
            </a>
          </AssetDocumentationContent>
        </Container>
      </AssetDocumentationSection>
    </>
  );
};

export default GroETH;
