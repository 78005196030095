export default [
  {
    inputs: [{ internalType: 'address payable', name: '_token', type: 'address' }],
    name: 'depositFeeOnlyBNB',
    outputs: [],
    stateMutability: 'payable',
    type: 'function',
  },
  { stateMutability: 'payable', type: 'receive' },
];
