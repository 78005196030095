/* eslint-disable react/prop-types */
/** @jsxImportSource theme-ui */
import React from 'react';
import styled from 'styled-components';
import { Button, Flex } from 'theme-ui';
import { theme } from '@groot/shared/util';

const StyledFeatureCard = styled(Flex)`
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 8px !important;
  margin-bottom: 8px !important;
  padding: 20px;
  border-radius: 5px;
  background-color: #1c1c1c;
  text-align: center;

  ${theme.mediaQuery.min.sm} {
    width: calc((100% / 3) - 20px);
    margin: 0 !important;
  }

  p {
    margin-bottom: 20px;
    font-size: 14px;
    color: #9c9c9c;

    &:last-child {
      margin: 0;
    }
  }
`;

const Icon = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;

  svg {
    display: block;
  }
`;

const Title = styled.h3`
  margin-bottom: 8px;
  font-size: 22px;
`;

const Link = styled.a`
  display: block;
  width: 100%;
  margin-top: auto;
  margin-bottom: 0;
`;

const FeatureCard = ({ icon, title, children, href, ...props }) => (
  <StyledFeatureCard {...props}>
    <Icon>{icon}</Icon>
    <Title>{title}</Title>
    {children}
    {href && (
      <Link href={href}>
        <Button variant="primary" sx={{ width: '100%', height: 50, pr: 30, pl: 30, fontSize: 16 }}>
          Enter {title}
        </Button>
      </Link>
    )}
  </StyledFeatureCard>
);

export default FeatureCard;
