import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ReactNotification from 'react-notifications-component';

import App from './app';
import 'react-notifications-component/dist/theme.css';

function MainApp() {
  return (
    <Router>
      {/* <ReactNotification /> */}
      <Switch>
        <Route path="/" component={App} />
      </Switch>
    </Router>
  );
}

export default MainApp;
